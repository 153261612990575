/*
 * VDS COVERFLOW
 */
.vds-coverflow .swiper-slide {
	background-color: #2598d5;
	color: #fff;
	/*max-height: 500px;*/
	height: auto;
	max-width: 734px;
	border-bottom-right-radius: 2rem;
}

.vds-coverflow.swiper-container {
	overflow: visible;
}

.vds-coverflow a {
	text-decoration: none;
	color: #ffffff;
}
.vds-coverflow-bottom-text h2:hover,
.vds-coverflow a:hover {
	text-decoration: none;
	color: #003e90;
}

@media (min-width: 1162px) {
    .vds-coverflow {
		margin-left: calc((100vw - 1162px) / 2);
	}
}

@media (min-width: 768px) {
/*@media (min-width: 992px) {*/
	.vds-coverflow-bottom-text a svg {
		display: none;
	}
}

.vds-coverflow-bottom img.img-fluid {
	min-width: 100%;
}

.vds-coverflow-top img {
	width: 100%;
}

.vds-coverflow-bottom .row {
	padding: 0;
	margin: 0;
}

.vds-coverflow-bottom-text h1 {
	font-size: 16px;
	color: #fff;
	margin: 15px 0;
	line-height: normal;
}

.vds-coverflow-bottom-text h2 {
	font-size: 32px;
	color: #fff;
	line-height: normal;
	/*margin: 15px 0;*/
}

.vds-coverflow-bottom-link {
	min-height: 20px;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 20px;
}

.vds-coverflow-bottom-link span {
	flex-grow: 1;
}


.vds-coverflow-bottom-link a {
	display: flex;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.vds-coverflow-bottom-link a {
		height: 15px;
	}
    .vds-coverflow-bottom-link svg {
		position: relative;
		top: -6px;
    }
    .vds-coverflow-bottom-text a svg {
		position: relative;
		left: calc(-50% + 15px);
    }
}
.vds-coverflow-bottom-links.row{
	margin-top: 15px;
}

.vds-coverflow-bottom-text a svg {
	height: 15px;
}

.vds-coverflow-bottom-link a svg,
.vds-coverflow-bottom-links img {
	flex-basis: 25px;
	align-self: flex-start;
	margin: 5px 0 0 5px;
	flex-shrink: 0
}

.vds-coverflow .swiper-slide {
	opacity: 0;
}

.vds-coverflow .swiper-slide.swiper-slide-next,
.vds-coverflow .swiper-slide.swiper-slide-prev {
	opacity: 0.5;
}

.vds-coverflow .swiper-slide.swiper-slide-active {
	opacity: 1;
}

.vds-coverflow .swiper-button-prev {
	background-image: url(../Icons/Pfeile/Pfeil_Weiss_Nach_Links.svg);
}

.vds-coverflow .swiper-button-next {
	background-image: url(../Icons/Pfeile/Pfeil_Weiss_Nach_Rechts.svg);
}

@media (min-width: 1134px) {
	.vds-coverflow .swiper-button-prev {
		left: calc((100% - 754px) / 2 - 27px);
	}

	.vds-coverflow .swiper-button-next {
		right: calc((100% - 754px) / 2 - 27px);
	}
}

@media (max-width: 1133px) {
	.vds-coverflow .swiper-button-prev {
		left: 0;
	}

	.vds-coverflow .swiper-button-next {
		right: 0;
	}
}

@media (min-width: 768px) {
/*@media (min-width: 992px) {*/
	.vds-coverflow .swiper-button-prev {
		background-image: url(../Icons/Pfeile/Pfeil_Weiss_Nach_Links.svg);
	}

	.vds-coverflow .swiper-button-next {
		background-image: url(../Icons/Pfeile/Pfeil_Weiss_Nach_Rechts.svg);
	}

	#abovecontent .scroll-down img {
		height: 40px;
		width: auto;
	}
}

@media (max-width: 767px) {

	.vds-coverflow-bottom-text h1 {
		font-size: 16px;
		line-height: 24px;
	}
	.vds-coverflow-bottom-text h2 {
		font-size: 20px;
		line-height: 28px;
	}

	.vds-coverflow .swiper-button-prev {
		left: 15px;
	}

	.vds-coverflow .swiper-button-next {
		right: 15px;
	}

	.vds-coverflow .swiper-button-next,
	.vds-coverflow .swiper-button-prev {
		display: none;
	}

	.vds-coverflow-buttonPrev,
	.vds-coverflow-buttonNext {
		bottom: calc((100vh - 200px) / 4);
		position: relative;
	}

	.vds-coverflow-buttonPrev img,
	.vds-coverflow-buttonNext img {
		height: 23px;
		width: auto;
	}

	#abovecontent .scroll-down img{
		width: 23px;
		height: auto;
		position: relative;
		top: 21px;
	}
}

.vds-coverflow-buttonPrev,
.vds-coverflow-buttonNext,
.vds-coverflow-center,
#abovecontent .scroll-down {
    bottom: calc((100vh - 355px) / 4);
	position: relative;
	color: #003e90;
}

.vds-coverflow-mobile{
	position: absolute;
	bottom: 0;
	height: auto !important;
}

.mobile-left {
    display: block;
    width: 62px;
    text-align: left;
}
.mobile-left img{
	display: block;
    margin-right: auto;
	height:30px;
	width:20px;
}
.mobile-center {
    display: block;
    width: 62px;
	margin-top: 13px;
    margin-left: auto;
    margin-right: auto;
}
.mobile-center img{
	display: block;
    margin-left: auto;
    margin-right: auto;
	height:20px;
	width:30px;
}
.mobile-right {
    display: block;
    width: 62px;
    text-align: right;
}
.mobile-right img{
	display: block;
    margin-left: auto;
	height:30px;
	width:20px;
}
.mobile-left a, .mobile-center a, .mobile-right a{
	color: #003e90;
}
.vds-coverflow-mobile{
	margin-bottom: 10px;
    padding-left: 35px;
    padding-right: 35px;
}
@media all and (max-width: 768px) and (min-height: 900px){
    .vds-coverflow-mobile {
        bottom: calc(100vh - 900px);
    }
}
