/*
 * VDS STELLENANGEBOT
 */
.vds-stellenangebot.row {
    padding: 50px 0;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
	margin-bottom: -1px;
	margin-left: 0;
    margin-right: 0;
}

.vds-stellenangebot.row .col-12 {
	padding-left: 0;
    padding-right: 0;
}

.vds-stellenangebot h1 {
	font-size: 28px; line-height: 35px;
}

.vds-stellenangebot a {
    text-decoration: none;
}
#content a.stellenangebotbutton {
	display: inline-block;
	padding-bottom: 30px;
}


.vds-stellenangebot .order-md-1 {
    color: #6c6e6f;
}

.vds-stellenangebot-button {
    background-color: #dfe1e3;
	border-bottom-right-radius: 2rem;
	padding: 10px 20px;
}

.vds-stellenangebot-button:hover {
    background-color: #2598d5;
}

.vds-stellenangebot span {
	display: inline-block;
}
.vds-stellenangebot span.description {
	width: 100%;
}

.vds-stellenangebot span.label {
    color: #fff;
	font-size: 20px;
	margin-bottom: 0;
}

.vds-stellenangebot span.number {
    color: #fff;
	font-size: 76px;
	line-height:76px;
	font-weight: bold;
}

.vds-stellenangebot .order-md-1 img{
	height: 21px;
	margin-top: -12px;
	margin-right: 20px;
}

.vds-stellenangebot .order-md-2 img,
.vds-stellenangebot .order-md-2 svg{
    height: 15px;
	display: inherit;
}

.vds-stellenangebot .order-md-2 svg{
    margin-top: 5px;
}

.vds-stellenangebot .order-2 span:not(:last-of-type) {
	margin-bottom: 30px;
	line-height: 30px;
}

@media (min-width: 768px) {
	.vds-stellenangebot .order-md-1 h1 {
		margin-bottom: 20px;
	}

	.vds-stellenangebot.row .col-12.order-md-1 {
		padding-right: 15px;
	}
}

@media (max-width: 767px) {
	.vds-stellenangebot-button {
		margin-bottom: 10px;
	}

	.vds-stellenangebot-button span {
		display: inline;
	}

	.vds-stellenangebot-button img,
	.vds-stellenangebot-button svg {
		float: right;
		margin-top: 35px;
	}
}