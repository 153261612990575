/*
 * VDS CONTACT
 */
.vds-contact {
	width: 100%;
	margin-left: 0;
	margin-bottom: 40px;
	border: 0px solid lime;
}



.vds-contact .row {
	margin-bottom: 0;
}

@media (max-width: 767px) {
    .vds-contact {
		width: 100%;
	}
}

.vds-contact-image {
	padding: 0;
	margin-bottom: 30px;
	border-bottom: 9px solid #2598d5;
}

.vds-contact-image img {
	height: auto;
	float: right;
}

.vds-contact-name,
.vds-contact-tel,
.vds-contact-fax {
	margin: 0;
	width: 100%;
}

.vds-contact-name .vds-contact-name-text a{
	color: #003d90;
	text-decoration: none;
}

.vds-contact-name .vds-contact-name-img,
.vds-contact-tel .vds-contact-tel-img,
.vds-contact-fax .vds-contact-fax-img{
	width: 27px;
	height: 100%;
	max-height: 27px;
	padding: 0;
	margin: 12px 7px 0 0;
}

.vds-contact-name .vds-contact-name-space,
.vds-contact-tel .vds-contact-tel-space,
.vds-contact-fax .vds-contact-fax-space{
	margin-bottom: 4px;
	border-bottom: 1px solid #dfe1e3;
}

.vds-contact-name .vds-contact-name-text,
.vds-contact-tel .vds-contact-tel-text,
.vds-contact-fax .vds-contact-fax-text{
	padding: 0;
	margin: 15px 0;
	font-size: 16px;
	line-height: 16px;
	color: #003d90;
}

@media (max-width: 767px) {
	.vds-contact.row {
		padding-left: 25vw;
		margin-top: 0;
	}
	.vds-contact .row {
		margin: 15px 0;
	}

	.vds-contact-name .vds-contact-name-img,
	.vds-contact-tel .vds-contact-tel-img,
	.vds-contact-fax .vds-contact-fax-img{
		margin: 0;
		position: relative;
		left: calc(-25vw + 30px);
	}

	.vds-contact-name .vds-contact-name-text,
	.vds-contact-tel .vds-contact-tel-text,
	.vds-contact-fax .vds-contact-fax-text{
		position: relative;
		left: -27px;
	}

	.vds-contact-tel .vds-contact-tel-text,
	.vds-contact-fax .vds-contact-fax-text {
		margin-left: -8px;
	}

	.vds-contact-name .vds-contact-name-text,
	.vds-contact-tel .vds-contact-tel-text,
	.vds-contact-fax .vds-contact-fax-text {
		margin: auto;
		line-height: 16px;
	}
}