/*
 * Tab-Box
 */
.tab-box{
	margin: 30px 0;
}
.tab-box .col-12{
	padding-left: 0;
	padding-right: 0;
}
.tab-box .nav-tabs{
	padding-left: 5px;
	border: 0;
	justify-content: space-between;
}
.tab-box .nav-item.nav-link{
	color:#ffffff;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border: none;
	background-color: #2699d6;
	margin-right: 5px;
	margin-bottom: 5px;
	padding: 10px 25px;
	flex-grow: 1;
	text-align: center;
}
.tab-box .nav-item.nav-link.first{
	margin-left: -5px;
}
.tab-box .nav-item.nav-link.active{
	color:#003e90;
	margin-left: -5px;
    margin-right: 0;
	padding: 5px 30px 10px 30px;
	background-color:#ffffff;
    border-top: 5px solid #003e90;
}
.tab-box .nav-item.nav-link.active.first{
	padding-left: 25px;
}
.tab-box .nav-item.nav-link.active.last{
	margin-right: 0px;
	padding-right: 25px;
}
.tab-box .tab-content{
	padding-top: 40px;
}
.tab-box .tab-content img{
	float: left;
	max-width: 35%;
	margin-right: 5%;
	margin-top: 8px;
	margin-bottom: 10px;
}

@media (max-width: 575px) {
	.tab-box .tab-content img{
		display: none;
	}
}

.tab-box h1, .tab-box h2, .tab-box h3, .tab-box h4, .tab-box h5{
	padding-bottom: 20px;
}

#content .tab-box .nav-link {
	color: #ffffff;
}
#content .tab-box .nav-link.active {
	color: #003e90;
}