/* FONTS */
@font-face {
    font-family: 'DinProRegular';
    src: url('../Fonts/DINPro.eot');
    src: url('../Fonts/DINPro.eot?#iefix') format('embedded-opentype'),
    url('../Fonts/DINPro.woff') format('woff'),
    url('../Fonts/DINPro.woff2') format('woff2'),
    /*url('../Fonts/DINPro.svg#DINPro') format('svg')*/;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DinProMedium';
    src: url('../Fonts/DINPro-Medium.eot');
    src: url('../Fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('../Fonts/DINPro-Medium.woff') format('woff'),
    url('../Fonts/DINPro-Medium.woff2') format('woff2'),
    /*url('../Fonts/DINPro-Medium.svg#DINPro-Medium') format('svg')*/;
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'Din Pro Regular';
    src: url('../Fonts/DINPro.eot');
    src: url('../Fonts/DINPro.eot?#iefix') format('eot'),
    url('../Fonts/DINPro.woff') format('woff'),
    url('../Fonts/DINPro.woff2') format('woff2'),
    /*url('../Fonts/DINPro.svg#DINPro') format('svg')*/;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Din Pro Medium';
    src: url('../Fonts/DINPro-Medium.eot');
    src: url('../Fonts/DINPro-Medium.eot?#iefix') format('eot'),
    url('../Fonts/DINPro-Medium.woff') format('woff'),
    url('../Fonts/DINPro-Medium.woff2') format('woff2'),
    /*url('../Fonts/DINPro-Medium.svg#DINPro-Medium') format('svg')*/;
    font-weight: bold;
    font-style: normal;
}


body {
    font-family: 'DinProRegular', Arial, sans-serif;
    background-color: #ffffff;
    font-size: 16px;
    line-height: 22px;
    font-size: 19px;
    line-height: 33px;
}

#header-top {
    font-size: 16px;
    line-height: 22px;
}

main .row {
    margin-bottom: 40px;
}

main .row.content {
    margin-bottom: 0;
}

#abovecontent_subpages {
    margin-top: 0px;
    padding: 0;
}

#abovecontent_subpages .row {
    margin: 0;
}

#abovecontent_subpages .row .col-12 {
    padding: 0;
}

@media (max-width: 992px) {
    #abovecontent_subpages {
        margin-top: 70px;
    }
}

*:focus {
    outline: none;
}

#content a {
    color: #51aee2;
}

#content a:hover {
    color: #51aee2;
    text-decoration: underline;
}

.vds-three-col-slider-text h1,
.vds-three-col-slider-text h2,
.vds-download-box-header h2 {
    word-break: break-word;
    word-wrap: break-word;
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

/* DETECT IE10 + 11 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    * {
        /*word-break: break-all;*/
    }
}

/* DETECT EDGE */
@supports (-ms-ime-align:auto) {
    * {
        /*word-break: break-all;*/
    }
}

@media (max-width: 767px) {
    .ce-headline-noMobi {
        display: none;
    }
}

@media (max-height: 767px) {
    #abovecontent,
    #abovecontent #slider {
        min-height: 767px;
    }

}

@media (min-width: 768px) {
    .ce-headline-noDesk {
        display: none;
    }
}


body, h1, h2, h3, h4, h5, span {
    font-family: "DinProRegular";
}

h1 {
    font-size: 80px;
    line-height: 95px;
}

h2 {
    font-size: 34px;
    line-height: 50px;
}

h3 {
    font-size: 28px;
    line-height: 35px;
    margin: 0;
}

h4 {
    font-size: 19px;
    line-height: 33px;
    margin: 0;
}

h5 {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
}

button:focus {
    outline: 0;
}

.navi1,
.navi2 {
    font-family: "DinProMedium";
}

#main {
    padding-top: 30px;
    margin-top: 30px;
}

header [type=reset],
header [type=submit],
header button,
header [type=button] {
    -webkit-appearance: none;
}

dl, ol, ul {
    margin: 0;
}

.figure {
    width: 100%;
    margin: 2em 0;
}

#content,
#footer .container-fluid,
#subfooter .container-fluid {
    max-width: 1146px;
}

#content.content-full-width {
    max-width: none;
}

.grid-fw-bg {
    overflow: hidden;
}
.grid-fw-bg .bg-content,
.grid-fw-bg .bg-content .image,
.grid-fw-bg .bg-content .img-fluid
{
    width: 100%;
}
.grid-fw-minh {
    min-height: 400px;
}
.grid-fw-bg .vds-button,
.grid-fw-bg .vds-button .vds-button-text
{
    display: inline-block;
    width: auto;
}
.grid-fw-bg .vds-button .vds-button-text
{
    font-family: 'DIN Pro Medium';
    color: #003e90;
    font-size: 1.25rem;
}
.grid-fw-bg .vds-button
{
    min-height: auto;
}
.grid-fw-bg .vds-button:hover .vds-button-text
{
    color: #fff;
}
.grid-fw-bg h1 + p, .grid-fw-bg h2 + p,
.grid-fw-bg .vds-button + *
{
    margin-top: 2.5em;
}
.grid-fw-bg .bg-content {
    display: none;
}
.grid-fw-bg .bg-content.bg-mobile-static {
    display: block;
    position: relative;
    top: 100px;
}
.grid-fw-bg .fg-content {
    margin-top: 75px;
}
.grid-fw-bg .fg-content-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.grid-fw-bg .fg-content-inner > * {
    order: 1;
}
.grid-fw-bg .fg-content-inner > .vds-button {
    order: 2;
}

@media (min-width: 768px) {
    .grid-fw-minh {
        min-height: 600px;
    }
    .grid-fw-bg .vds-button
    {
        min-height: 100px;
    }
}
@media (min-width: 992px) {
    .grid-fw-bg > .bg-content {
        display: block;
        position: absolute;
        top: 0;
    }
    .grid-fw-bg .fg-content-inner > .vds-button {
        order: 1;
    }
    .grid-fw-bg .bg-content .img-fluid
    {
        min-height: 800px;
    }
    .grid-fw-minh {
        min-height: 600px;
    }
    .grid-fw-bg .fg-content {
        margin-top: 260px;
    }
}

#abovecontent .row .row:not(.vds-coverflow-bottom-text):not(.vds-coverflow-bottom-links):not(.vds-suche) {
    max-width: 1568px;
    height: auto;
}

@media (min-height: 768px) {
    #abovecontent .row .row:not(.vds-coverflow-bottom-text):not(.vds-coverflow-bottom-links):not(.vds-suche) {
        margin: calc((100vh - 300px) / 2 - 60px) auto
    }
}

@media (max-height: 767px) {
    #abovecontent .row .row:not(.vds-coverflow-bottom-text):not(.vds-coverflow-bottom-links):not(.vds-suche) {
        margin: calc((767px / 2 - 120px) / 2) auto;
    }
}

#abovecontent .row .col-12:not(.vds-suche) {
    height: 100%;
}

@media (min-width: 992px) {
    .tx-indexedsearch-searchbox:not(.scrolled) .tx-indexedsearch-hidden-fields {
        height: 140px;
    }

    .tx-indexedsearch-searchbox.scrolled .tx-indexedsearch-hidden-fields {
        height: 180px;
    }
}

@media (max-width: 991px) {
    .tx-indexedsearch-searchbox .tx-indexedsearch-hidden-fields {
        height: 80px;
    }
}

.tx-indexedsearch-searchbox .tx-indexedsearch-searchbox-sword {
    width: 100%;
    border: none;
    height: 70px;
    padding-left: 15px;
}

.tx-indexedsearch-searchbox .tx-indexedsearch-form {
    padding-right: 0;
}

.tx-indexedsearch-searchbox .tx-indexedsearch-search-submit {
    padding-left: 0;
    border: none;
}

.tx-indexedsearch-searchbox .tx-indexedsearch-searchbox-button {
    height: 70px;
    width: 70px;
    /*color: white;*/
    background-color: #52ade2;
    border: 1px solid white;
    border-bottom-right-radius: 1rem;
    background: url("../Icons/Hauptnavi/Lupe_Icon_Weiss.svg");
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
}

.tx-indexedsearch-searchbox .tx-indexedsearch-searchbox-button:hover {
    background-color: #76beea;
}

.tx-indexedsearch-searchbox .tx-indexedsearch-searchbox-button:active {
    /*color: #003e90;*/
    border: 1px solid #2699d6;
    background: url("../Icons/Hauptnavi/Lupe_Icon_Hauptnavi.svg");
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
}

.tx-indexedsearch-searchbox .tx-indexedsearch-hidden-fields {
    background-color: #52ade2;
}

.tx-indexedsearch-searchbox {
    background-color: #52ade2;
    padding-bottom: 115px;
    border-bottom-right-radius: 2rem;
}

#abovecontent .row.slider-wrapper {
    height: 100vh;
    overflow: hidden;
}

@media (max-width: 768px) {
    #abovecontent .row.slider-wrapper {
        max-height: 900px;
    }
}

@media all and (max-width: 768px) and (min-height: 900px) {
    #abovecontent .row .row:not(.vds-coverflow-bottom-text):not(.vds-coverflow-bottom-links) {
        margin: calc((900px - 300px) / 2 - 60px) auto;
    }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    #abovecontent .scroll-down img {
        position: relative;
        left: 35%;
    }
}

#abovecontent .scroll-down {
    margin: auto;
    position: relative;
}

@media (min-width: 768px) {
    #abovecontent .scroll-down {
        bottom: calc((100vh - 300px) / 8);
    }
}

@media (max-width: 767px) {
    #abovecontent .scroll-down {
        bottom: calc((100vh - 300px) / 4);
    }
}

.scroll-down-anim {
    opacity: 0;
    animation: scrollArrowAnim 1.4s 0s infinite normal forwards ease-in-out;
    position: relative;
}

@keyframes scrollArrowAnim {
    0% {
        opacity: 0;
        top: -40px;
    }
    50% {
        opacity: 1;
        top: -30px;
    }
    100% {
        opacity: 0;
        top: -20px;
    }
}

@-webkit-keyframes scrollArrowAnim {
    0% {
        opacity: 0;
        top: -40px;
    }
    50% {
        opacity: 1;
        top: -30px;
    }
    100% {
        opacity: 0;
        top: -20px;
    }
}


.navi1, .navi2 {
    font-family: "DinProMedium";
}

.navi1 {
    font-size: 17px;
}

.navi2 {
    font-size: 14px;
}


.subnavigation {
    font-size: 15px;
    line-height: 30px;
    font-family: "DinProMedium";
    border-bottom: 1px solid #c6c6c6;
}

.subnavigation li {
    border-top: 1px solid #c6c6c6;
    position: relative;
}

.subnavigation li img.hassubicon {
    height: 20px;
    width: auto;
    position: absolute;
    right: 10px;
    top: 20px;
}

.subnavigation li.active img.hassubicon {
    display: none;
}

.subnavigation li.active li.hassub img.hassubicon {
    display: inline-block;
}

.subnavigation li a,
.subnavigation li span {
    padding: 15px 10px 15px 0;
    display: inline-block;
}

.subnavigation li a span {
    padding: 0;
}

.subnavigation ul.nested span {
    padding-left: 20px;
}


.subnavigation li {
    background-color: #fff;
    padding-right: 10px;
    width: 100%;
}

.subnavigation li.active a span,
.subnavigation li.current a span,
.subnavigation a span {
    color: #6c6e6f;
    font-weight: normal;
}

.subnavigation li.active span,
.subnavigation li.current span,
.subnavigation li.active > a span,
.subnavigation a:hover span {
    color: #003C8D;
}


.subnavigation li.active a:hover span,
.subnavigation li.current a:hover span {
    color: #51aee2;
}

.subnavigation li ul.nested li,
.subnavigation li ul.nested li a {
    display: inline-block;
}

.subnavigation li ul.nested {
    position: absolute;
    z-index: 99;
    right: -100%;
    top: -1px;
    display: none;
    width: 100%;
    -moz-transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: max-height 0.5s ease-out;
    -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.2);
}

.subnavigation li:hover ul.nested {
    display: flex;
    -moz-transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: max-height 0.5s ease-in;
}

.subnavigation li ul.nested ul.nested {
    display: none;
}

.subnavigation li:hover ul.nested ul.nested {
    display: none;
}

.subnavigation li:hover ul.nested ul.nested ul.nested {
    display: none;
}

.subnavigation li:hover ul.nested ul.nested ul.nested ul.nested ul.nested {
    display: none;
}

.subnavigation li:hover ul.nested li.hassub:hover ul.nested {
    display: flex;
}

.subnavigation li:hover ul.nested li.hassub:hover ul.nested ul.nested {
    display: none;
}

.subnavigation li:hover ul.nested ul.nested li.hassub:hover ul.nested {
    display: flex;
}

.subnavigation li:hover ul.nested ul.nested ul.nested li.hassub:hover ul.nested {
    display: flex;
}

.subnavigation li:hover ul.nested ul.nested ul.nested li.hassub:hover ul.nested {
    display: flex;
}

.subnavigation li.active ul.nested li:hover ul.nested ul.nested {
    display: none;
}

.subnavigation li.active ul.nested {
    position: relative;
    right: 0;
    top: 0;
    display: block;
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.subnavigation ul.nested li:hover ul.nested {
    position: absolute;
    z-index: 99;
    right: -100%;
    top: -1px;
    display: none;
    width: 100%;
    -moz-transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: max-height 0.5s ease-out;
    -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    -moz-transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: max-height 0.5s ease-in;
}

.subnavigation li.hassub:hover {

}

.subnavigation li.hassub:hover a span {
    color: #003C8D;
}

.subnavigation li.hassub:hover li a span {
    color: #6c6e6f;
}

.subnavigation li.hassub li:hover a span {
    color: #003C8D;
}

.subnavigation li.hassub li.hassub:hover li a span {
    color: #6c6e6f;
}

.subnavigation li.hassub li.hassub li:hover a span {
    color: #003C8D;
}

.subnavigation li.hassub li.hassub li.hassub:hover li a span {
    color: #6c6e6f;
}

.subnavigation li.hassub li.hassub li.hassub li:hover a span {
    color: #003C8D;
}

.subnavigation li.hassub li.hassub li.hassub li.hassub:hover li a span {
    color: #6c6e6f;
}

.subnavigation li.hassub li.hassub li.hassub li.hassub li:hover a span {
    color: #003C8D;
}

@media (min-width: 768px) {
    .navi1 {
        font-size: 15px;
    }

    .navi2 {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1140px) {
    .navi1 {
        font-size: 17px;
    }

    .navi2 {
        font-size: 14px;
    }
}

@media only screen and (max-width: 768px) {
    /* MOBILE */
    h1 {
        font-size: 36px;
        line-height: 45px;
    }

    h2 {
        font-size: 20px;
        line-height: 28px;
    }

    h3 {
        font-size: 18px;
        line-height: 24px;
    }

    h4 {
        font-size: 15px;
        line-height: 20px;
    }

    body {
        font-size: 15px;
        line-height: 22px;
    }

    #main .subnav {
        display: none;
    }

    footer .order-2 p {
        text-align: center;
    }

}

/* SMALL FONT */
.small-font {
    font-size: 14px;
}

@media only screen and (max-width: 767px) {
    .small-font {
        font-size: 14px;
    }
}

/* COLORS */
.back-color1, .back-color1 a {
    background-color: #003e90;
}

.back-color2, .back-color2 a {
    background-color: #2699d6;
}

.back-color3, .back-color3 a {
    background-color: #51aee2;
}

.back-color4, .back-color4 a {
    background-color: #76beea;
}

.back-no-color1, .back-no-color1 a {
    background-color: #373a3b;
}

.back-no-color2, .back-no-color2 a {
    background-color: #6c6e6f;
}

.back-no-color3, .back-no-color3 a {
    background-color: #c6c6c6;
}

.back-no-color4, .back-no-color4 a {
    background-color: #e0e2e3;
}

.back-no-color5, .back-no-color5 a {
    background-color: #ffffff;
}

.front-color1, .front-color1 a, h1, h2, h3, h4, h5 {
    color: #003e90;
}

.front-color2, .front-color2 a {
    color: #2699d6;
}

.front-color3, .front-color3 a {
    color: #51aee2;
}

.front-color4, .front-color4 a {
    color: #76beea;
}

.front-no-color1, .front-no-color1 a {
    color: #373a3b;
}

.front-no-color2, .front-no-color2 a {
    color: #6c6e6f;
}

.front-no-color3, .front-no-color3 a {
    color: #c6c6c6;
}

.front-no-color4, .front-no-color4 a {
    color: #e0e2e3;
}

.front-no-color5, .front-no-color5 a {
    color: #ffffff;
}

.main-nav-color a {
    color: #ffffff
}

.main-nav-color a img {
    fill: #51aee2;
}

.main-nav-color a:focus,
.main-nav-color a:hover,
.main-nav-color a:active {
    color: #003e90;
    background-color: initial
}

.main-nav-color a:focus img,
.main-nav-color a:hover img,
.main-nav-color a:active img {
    fill: #003e90;
}

/* GENERAL */
/* FORCED FULL WIDTH */
.container-full {
    width: 100%;
    margin: 0;
    padding: 0;
}

@media (min-width: 786px) {
    .container .full-container {
        position: relative;
        margin-left: calc(63.75% - 100%)
    }

    footer .follow-icons-wrapper {
        text-align: left;
    }
}

/* OBLIGATORY HIDE CLASS */
.hide {
    display: none;
}

/* WRAPPED TEXT WITH PREPENDED OR APPENDED ICON */
.icon-prepend img {
    position: relative;
    /*top: -0.1em;*/
    height: 2em;
    width: 2em;
    margin-right: 0.5em;
}

.icon-append img {
    position: relative;
    /*top: -0.1em;*/
    height: 1.5em;
    width: 1.5em;
}

/* HELPER CLASS FOR CURSOR */
.clickable {
    cursor: pointer;
    user-select: none;
}

.sub-item {
    width: 94%;
    margin-left: 3%;
    padding: .25rem 0;
}


main .keyvisual-wrapper {
    background-color: transparent;
    margin-bottom: 0px;
    text-align: center;
}


/* PREFOOTER (AKA NACH OBEN) */
.prefooter {
    font-size: 12pt;
    padding-top: 4rem;
    padding-bottom: 1rem;
}

.prefooter div {
    max-width: 1146px;
    font-family: 'DinProMedium';
    padding-bottom: 30px;
}

.prefooter img,
.prefooter svg {
    width: 20px;
    margin: 0 2px 0 12px;
    float: right;
    padding-top: 5px;
    height: 20px;
}

.prefooter span {
    float: right;
}

.prefooter span a {
    color: #6c6e6f;
    text-decoration: none;
}

.prefooter span a:hover {
    color: #51aee2;
    text-decoration: none;
}

/* FOOTER */
#footer {
    background-color: rgba(118, 190, 234, 0.5);
    padding-top: 4rem;
}

#footer h3 {
    color: #000000;
}

.padding-bottom-sm-lg {
    padding-bottom: 3rem
}

/* AFTERFOOTER (AKA TOP) */
.afterfooter {
    font-size: 10pt;
    width: 70px;
    height: 65px;
    float: right;
    background-color: #2598d5;
    border-top-left-radius: 1rem;
    text-align: center;
}

.afterfooter a {
    color: #fff;
    top: 20%;
    position: relative;
    text-decoration: none;
    display: table;
    margin: 0 auto;
}

.afterfooter a:hover span {
    color: #003e90;
}

.afterfooter img,
.afterfooter svg {
    width: 20px;
}

.afterfooter span {
    display: block;
    color: #fff;
}


/* HELPER CLASSES FOR MARGINS */
#footer .margin-top {
    margin-top: 1rem;
}

@media (max-width: 767px) {

    .container-fluid {
        margin: 0;
    }

    #footer {
        padding-top: 0;
    }

    .padding-bottom-sm-lg {
        padding-bottom: 0;
    }

    #footer .margin-top {
        margin-top: 0;
    }

    #footer .margin-top-xs-sm,
    #subfooter .margin-top-xs-sm {
        margin-top: 1rem;
    }

    #footer .padding-top-xs-sm,
    #subfooter .padding-top-xs-sm {
        padding-top: 1rem;
    }

    /* FIX MAX WIDTH OF CONTAINER FOR MD */
    #footer .container {
        max-width: initial;
    }
}

#footer .margin-bottom {
    margin-bottom: 1.5rem;
}

/* FOOTER MAIN PART RESPONSIVE */
@media (max-width: 767px) {
    #footer .container-full {
        padding-top: 1rem;
        padding-bottom: 0;
    }

    #footer .address-wrapper,
    #footer .follow-icons-wrapper {
        width: calc(100% - 15px);
        margin-left: 15px;
        padding: 0rem 0.5rem 1rem 25vw;
    }

    #footer .address-wrapper {
        margin-right: 1rem;
        border-top: 1px solid #373a3b;
    }

    #footer .address-wrapper .clickable {
        display: flex !important;
        justify-content: space-between;
    }
}

/* FOOTER NAV MENU (SUBFOOTER) */

#subfooter {
    background-color: #ffffff;
    padding-top: 30px;
    padding-bottom: 20px;
}

#subfooter .footer-menu {
    justify-content: flex-end;
}

#subfooter .footer-menu .nav-link {
    padding: 0 0.5rem;
}

/* FOOTER NAV MENU RESPONSIVE */
@media (max-width: 767px) {

    #subfooter {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    #subfooter,
    #subfooter a {
        background-color: #afd7f0;
    }

    #subfooter .footer-menu {
        display: block;
        justify-content: space-between;
    }

    #subfooter .footer-menu .nav-item {
        display: block;
        width: 100%;
        padding: 1rem 0.5rem 1rem 25vw;
        border-top: 1px solid #373a3b;
    }

    #subfooter .footer-menu .nav-link {
        display: flex;
        padding: 0;
        justify-content: space-between;
    }
}

/* FOLLOW US ICONS SPECIAL ADJUSTMENTS */
#follow-icons a {
    margin: 0 15px 0 0;
    fill: #3A3B3B;
}

#follow-icons a:hover,
#follow-icons a:focus {
    text-decoration: none;
    fill: #51AEE2;
}

#follow-icons svg {
    margin-right: 0.2em;
}

#follow-icons svg.linked-in-icon {
    margin-right: 0.4em;
}

#follow-icons svg.kununu-icon {
    margin-right: 0;
}

#follow-icons svg.facebook-icon {
    margin-left: -10px;
}

/* ARROW ICONS */
svg.pfeil-icon-white,
svg.pfeil-icon-white-on-blue {
    stroke: #FFFFFF;
}

svg.pfeil-icon-grey {
    stroke: #6C6E6F;
}

a:hover svg.pfeil-icon-white.pfeil-blue-on-hover {
    stroke: #003e90;
}

a:hover svg.pfeil-icon-grey.pfeil-blue-on-hover {
    stroke: #51aee2;
}

svg.pfeil-icon-white .st0,
svg.pfeil-icon-grey .st0,
svg.pfeil-icon-white-on-blue .st0 {
    fill: none;
    stroke-width: 1.3;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}

svg.pfeil-icon-white .st1,
svg.pfeil-icon-grey .st1,
svg.pfeil-icon-white-on-blue .st1 {
    fill: none;
    stroke-width: 1.3;
    stroke-linecap: round;
}

/* FORM OVERRIDE */
.frame-type-form_formframework .form-check {
    padding-left: 0;
}

.frame-type-form_formframework .row {
    margin-bottom: 0;
}

.frame-type-form_formframework .form-check-input {
    margin-left: 0;
    position: static;
}

.frame-type-form_formframework .radio span {
    margin-left: 5px;
}

.frame-type-form_formframework .radio span {
    margin-left: 5px;
}

.frame-type-form_formframework select,
.frame-type-form_formframework input,
.frame-type-form_formframework textarea {
    border-radius: 0;
}

.frame-type-form_formframework input {
    margin-right: 4px;
}

.frame-type-form_formframework .form-check input,
.frame-type-form_formframework .radio input {
    margin-right: 9px;
}

.frame-type-form_formframework .form-check-label,
.frame-type-form_formframework .radio > label {
    display: flex;
    align-items: center;
}

.frame-type-form_formframework input {
    flex-shrink: 0;
    align-self: flex-start;
}

.frame-type-form_formframework input:active,
.frame-type-form_formframework input:focus,
.frame-type-form_formframework textarea:active,
.frame-type-form_formframework textarea:focus {
    background-color: #dff0f9;
    border: 1px solid #2699d6;
    box-shadow: none;
    outline: none;
}


.frame-type-form_formframework input,
.frame-type-form_formframework textarea {
    border-color: #c6c6c6;
}

.frame-type-form_formframework input[required],
.frame-type-form_formframework input[required]:active,
.frame-type-form_formframework input[required]:focus,
.frame-type-form_formframework textarea[required]:active,
.frame-type-form_formframework textarea[required]:focus {
    border-color: #ca1240;
}

.frame-type-form_formframework input[required]:active,
.frame-type-form_formframework input[required]:focus,
.frame-type-form_formframework textarea[required]:active,
.frame-type-form_formframework textarea[required]:focus {
    background-color: #fedfdd;
    border-color: #ca1240;
}

.frame-type-form_formframework textarea {
    overflow-y: auto;
    max-height: 480px;
    scrollbar-width: 6px;
    scrollbar-color: #2699d6 #dff0f9;
}

.frame-type-form_formframework textarea:focus {
    -ms-scrollbar-track-color: #dff0f9;
    -ms-scrollbar-arrow-color: transparent;
    -ms-scrollbar-highlight-color: #2699d6;
}

.frame-type-form_formframework textarea::-webkit-scrollbar {
    width: 6px;
}
.frame-type-form_formframework textarea::-webkit-scrollbar-track {
    background-color: #dff0f9;
    border-left: 1px solid #2699d6;
}
.frame-type-form_formframework textarea::-webkit-scrollbar-thumb
{
    background-color: #2699d6;
}
.frame-type-form_formframework .fileupload-wrap .js-files-btn {
    display: none;
}
.frame-type-form_formframework .fileupload-wrap.js-files .js-files-btn {
    display: inline-block;
}
.frame-type-form_formframework .fileupload .help-block {
    display: block;
}
.frame-type-form_formframework .js-files .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}
.frame-type-form_formframework .js-files .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.frame-type-form_formframework select {
    background-color: #e0e2e3;
    color: #003e90;
    cursor: pointer;
}

.frame-type-form_formframework select:active,
.frame-type-form_formframework select:focus {
    background-color: #2699d6;
    box-shadow: none;
    border-color: #c6c6c6;
    color: #ffffff;
    outline: none;
}

.frame-type-form_formframework .singleselect {
    position: relative;
    width: 100%;
}

.frame-type-form_formframework .singleselect.active::after,
.frame-type-form_formframework .singleselect:active::after,
.frame-type-form_formframework .singleselect:focus::after {
    background-color: #2699d6;
    background-image: url("../Icons/Dot_Plus_Minus/Minus_Dot_Weiss_Icon.svg");
    border-color: #c6c6c6;
}

.frame-type-form_formframework .singleselect::after {
    background-color: #e0e2e3;
    background-image: url("../Icons/Dot_Plus_Minus/Plus_Dot_Dunkelblau_Icon.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    border: 1px solid #ced4da;
    border-left-width: 0;
    content: '';
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 5%;
}

.frame-type-form_formframework {
    font-size: 16px;
    line-height: 1.5;
}

.frame-type-form_formframework .row .clearfix p {
    margin-top: 1rem;
}

.frame-type-form_formframework .form-group {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.frame-type-form_formframework legend {
    border-bottom: 1px solid grey;
}

.frame-type-form_formframework label {
    font-weight: bold;
}

.frame-type-form_formframework .control-label {
    margin-bottom: 0;
}

.frame-type-form_formframework input[type="checkbox"],
.frame-type-form_formframework input[type="radio"] {
    width: 25px;
    height: 25px;
}

.frame-type-form_formframework .form-check-label.js > input[type="checkbox"],
.frame-type-form_formframework .radio input[type="radio"] {
    margin-top: 0;
}

.frame-type-form_formframework .form-check-label.js > span,
.frame-type-form_formframework .radio > label > span {
    /*because of font that is weirdly aligned even at line-height: 1*/
    margin-top: .1em;
}

.frame-type-form_formframework h2 {
    color: #003E90;
}

.frame-type-form_formframework .form-check input[type=checkbox],
.frame-type-form_formframework .form-check-label,
.frame-type-form_formframework .radio label {
    cursor: pointer;
}

.frame-type-form_formframework .radio input[type=radio],
.frame-type-form_formframework .form-check-label.js input[type=checkbox] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-style: solid;
    border-width: 1px;
}

.frame-type-form_formframework .radio input[type=radio] {
    border-radius: 50%;
}

.frame-type-form_formframework .radio input[type=radio]:checked {
    border: 9px solid #2699d6;
}

.frame-type-form_formframework .form-check-label.js input[type=checkbox]:checked {
    background-color: #2699d6;
    border: 1px solid transparent;
}

.frame-type-form_formframework .form-check-label.js {
    position: relative;
}

.frame-type-form_formframework .checkbox .help-block {
    margin: 5px 0 0 35px;
    display: block;
    font-size: .8em;
}

/**
 * begin checkmark
 * wenn der kram hier zu viele probleme macht:
 *
 * checkmark svg für datauri bei creneo anfordern
 * der 90grad-winkel (leicht anders im design) ist notwendig
 */
.frame-type-form_formframework .form-check-label.js.checked:before,
.frame-type-form_formframework .form-check-label.js.checked:after {
    height: 2px;
    z-index: 5;
    content: "";
    display: inline-block;
    position: absolute;
    background: #fff;
}

.frame-type-form_formframework .form-check-label.js.checked:before {
    top: 19px;
    left: 11px;
    width: 19px;
    transform: rotate(-60deg);
    transform-origin: 0;
}

.frame-type-form_formframework .form-check-label.js.checked:after {
    top: 15px;
    left: 4px;
    width: 9px;
    transform-origin: 0;
    transform: rotate(30deg);
}

/* ende checkmark */
.frame-type-form_formframework .input-group > input {
    margin-right: 0;
}

.frame-type-form_formframework .input-group > input + .input-group-append {
    border-right-width: 0;
}

.frame-type-form_formframework .input-group-append {
    border-left-width: 0;
}

.frame-type-form_formframework .input-group-text {
    border-radius: 0;
    color: #003C8D;
    background-color: #e0e2e3;
}

.frame-type-form_formframework .input-group > input:focus + .input-group-append .input-group-text {
    border-color: #2699d6;
    background-color: #2699d6;
    color: #fff;
}

.frame-type-form_formframework .input-group > input[required] + * .input-group-text {
    color: #ca1240;
    border-color: #ca1240;
}

.frame-type-form_formframework .input-group > input[required]:focus + * .input-group-text {
    border-color: #ca1240;
    background-color: #ca1240;
    color: #fff;
}


/* quick fixes for broken html structure...*/
.frame-type-form_formframework .form-group .radiobutton .form-group {
    margin: 0;
}

.frame-type-form_formframework .radiobutton > label:empty {
    display: none;
}

/*most browser support only :empty*/
.frame-type-form_formframework .radiobutton > label:blank {
    display: none;
}

#footer .telephone,
.mail {
    margin: 10px 0;
}