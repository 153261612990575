.desktop-carousel{
	margin: 0px;
}
.desktop-carousel .foreground,
.desktop-carousel .pikto,
.desktop-carousel .slide__content{
	position: absolute;
}
.desktop-carousel .foreground{
	width:100%;
}
.desktop-carousel .foreground img,
.desktop-carousel .background img{
    width: auto;
    height: auto;
}
.desktop-carousel .slide{
	position: relative;
	overflow: hidden;
}
.desktop-carousel .foreground{
	top:0;
	left:0;
}
.desktop-carousel .pikto{
	top: 8%;
	right: 5%;
}
.desktop-carousel .slide__content{
	top: 8%;
	left: 5%;
	max-width:40%;
	background-color: rgba(255,255,255,.85);
	padding: 15px 20px;
    font-size: 15px;
    line-height: 24px;
}

#content .desktop-carousel .slick-dots a{
    color: #ffffff;
    font-size: 15px;
}
#content .content .desktop-carousel .slick-dots li:before,
.desktop-carousel .slick-dots li:before{
	display:none;
}

.desktop-carousel .slick-dots li.slick-active{
	text-decoration: underline;
}

.desktop-carousel .slide__content--headings h4{
	margin-bottom: 0;
}
.desktop-carousel .slick-prev.slick-arrow,
.desktop-carousel .slick-next.slick-arrow {
    height: 100%;
    position: absolute;
	width: 25px;
    top: 0;
	border: none;
	background: none;
	padding: 0;
}
@media only screen and (max-width: 1242px){
	.desktop-carousel .slick-prev.slick-arrow,
	.desktop-carousel .slick-next.slick-arrow {
		display: none;
	}
}
.desktop-carousel .slick-prev.slick-arrow {
    left: 0px;
}

.desktop-carousel .slick-next.slick-arrow {
    right: 0px;
}
.desktop-carousel .link-arrow{
	background-image: url(../Icons/Pfeile/Pfeil_Lang_Grau.svg);
	background-repeat: no-repeat;
	background-size: contain;
	height: 15px;
	width:25px;
	display: block;
    margin-top: 25px;
}
@media only screen and (max-width: 952px){
	.desktop-carousel .slide__content{
		max-width:50%;
	}
	.desktop-carousel .background{
		overflow: hidden;
	}
}


/*
 * COPYPASTE OVERLAPPING CSS-CODE FROM QUOTESLIDER BEFORE THE SLICK SLIDER CONVERTION
 *
 * Gut, dass ich aufgepasst und mir nochmal den DesktopSlider angeschaut hatte,
 * nachdem ich mit dem QuoteSlider fertig geworden war. Hätte ich keine Sicherheits-
 * Kopie vom CSS gemacht, wäre das alles hier weg gewesen und du müsstest da
 * entsprechend nochmal ran.
 *
 *                                                                         - Daniel K.
 */

/* Custom arrow icons */
.desktop-carousel .slick-prev-icon {
    background-image: url("../Icons/Pfeile/Pfeil_Grau_Nach_Links.svg");
}
.desktop-carousel .slick-next-icon {
    background-image: url("../Icons/Pfeile/Pfeil_Grau_Nach_Rechts.svg");
}
.desktop-carousel .slick-prev-icon,
.desktop-carousel .slick-next-icon {
    width: 25px;
    height: 50px;
}
/* Positioning of controls outside of box */
.desktop-carousel .slick-prev {
    margin-left: -5%;
    -ms-flex-align: start;
    align-items: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.desktop-carousel .slick-next {
    margin-right: -5%;
    -ms-flex-align: baseline;
    align-items: baseline;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.desktop-carousel .slick-prev,
.desktop-carousel .slick-next {
    width: 5%;
    top: 40%;
}

/* Design Slider */
.desktop-carousel .slide {
    position: relative;
}
.desktop-carousel .slide .slide__img {
    overflow: hidden;
}
.desktop-carousel .slide .slide__img img {
    max-width: 100%;
    opacity: 1 !important;
    animation-duration: 3s;
    transition: all 1s ease;
}
.desktop-carousel .slide .animated {
    transition: all .5s ease;
}

/* Change animation presets */
.desktop-carousel .slider [data-animation-in] {
    opacity: 0;
    animation-duration: 1.5s;
    transition: opacity 0.5s ease 0.3s;
}

/* Dots */
.desktop-carousel .slick-dotted .slick-slider {
    margin-bottom: 30px;
}

.desktop-carousel .slick-dots {
    list-style: none;
    display: block;
    text-align: center;
    margin: 0;
    width: 100%;
    padding: 10px;
    background: #2699d6;
}

#content .content .desktop-carousel .slick-dots li,
.desktop-carousel .slick-dots li {
    color: #ffffff;
    padding: 10px 10px;
    width:auto;
    position: relative;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
}

@media only screen and (max-width: 768px){

    .desktop-carousel .slick-dots {
        list-style: none;
        display: block;
        text-align: center;
        margin: 0;
        width: 100%;
        padding: 0;
        background: #ffffff;
    }

    #content .content .desktop-carousel .slick-dots li,
    .desktop-carousel .slick-dots li {
        margin: 10px 5px;
        width: 45%;
        background: #2699d6;
        border-bottom-right-radius: 20px;
    }

    .desktop-carousel .slide__content {
        max-width: 90%;
        top: 20px;
        left:5%;
    }
    .desktop-carousel .slide_content_text {
        display: none;
    }
}


.desktop-carousel .slick-dots li button {
    border: 0;
    display: block;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    padding: 5px;
    cursor: pointer;
    transition: all .3s ease;
}
.desktop-carousel .slick-dots li button:hover,
.desktop-carousel .slick-dots li button:focus {
    outline: none;
}

.desktop-carousel .simple-dots .slick-dots li {
    width: 20px;
    height: 20px;
}

.desktop-carousel .simple-dots .slick-dots li button:hover,
.desktop-carousel .simple-dots .slick-dots li button:focus {
    opacity: 1;
}
.desktop-carousel .simple-dots .slick-dots li.slick-active button {
    color: white;
    opacity: 0.75;
}

.desktop-carousel .stick-dots .slick-dots li {
    height: 3px;
    width: 50px;
}
.desktop-carousel .stick-dots .slick-dots li button {
    position: relative;
    background-color: white;
    opacity: 0.25;
    width: 50px;
    height: 3px;
    padding: 0;
}
.desktop-carousel .stick-dots .slick-dots li button:hover ,
.desktop-carousel .stick-dots .slick-dots li button:focus {
    opacity: 1;
}
.desktop-carousel .stick-dots .slick-dots li.slick-active button {
    color: white;
    opacity: 0.75;
}
.desktop-carousel .stick-dots .slick-dots li.slick-active button:hover,
.desktop-carousel .stick-dots .slick-dots li.slick-active button:focus {
    opacity: 1;
}