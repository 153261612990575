/*
 * VDS BILDUNGSPASS
 */
.vds-bildungspass-text {
	margin-top: 39px;
	color: #6c6e6f;
	width: 100%;
	font-size: 15px;
	line-height: 24px;
}

.vds-bildungspass-text .col-12 {
	background: rgba(255,255,255,.85);
}

.vds-bildungspass-text h1,
.vds-bildungspass-text h2 {
	color: #003e90;
}

.vds-bildungspass-text h1 {
	margin-bottom: 20px;
	font-size: 28px; line-height: 35px;
}

.vds-bildungspass-text h2 {
	font-size: 16px; line-height: 22px;
}

.vds-bildungspass-text img,
.vds-bildungspass-text svg {
    height: 15px;
}



@media (min-width: 768px) {
	.vds-bildungspass-text .col-12 {
		padding: 25px 25px;
	}

	.vds-bildungspass-text img {
		margin-top: 0px;
	}
}

@media (min-width: 992px) {
	.vds-bildungspass-text .col-12 {
		padding: 30px 35px;
	}

	.vds-bildungspass-text img {
		margin-top: 15px;
	}
}


@media (min-width: 1200px) {
	.vds-bildungspass-text .col-12 {
		padding: 35px 45px;
	}

	.vds-bildungspass-text img {
		margin-top: 30px;
	}
}

@media (min-width: 768px) {
	.vds-bildungspass-text {
		position: absolute;
		top: 0;
		left: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.vds-bildungspass-img img.img-fluid {
		border-bottom: 40px solid #2598d5;
	}

	.vds-bildungspass img.img-fluid {
		padding: 0;
	}
}

@media (max-width: 767px) {
	.vds-bildungspass-text {
		margin-top: 0;
		padding: 0 15px 15px 15px;
		margin-left: 0;
		margin-right: 0;
	}

	.vds-bildungspass-img.col-12 {
		padding: 0;
	}
/*
	.vds-bildungspass {
		padding: 0 15px;
	}
*/
	.vds-bildungspass-text-blue {
		padding: 20px 15px;
		background-color: #2598d5;
		margin: 0 -15px 20px -15px;
	}

	.vds-bildungspass-text-blue h1,
	.vds-bildungspass-text-blue h2 {
		color: #fff;
	}

	.vds-bildungspass-text-blue h1 {
		margin: 0;
	}
}