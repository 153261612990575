/*
 * VDS NEWS KACHEL
 */
.vds-news .mb-d {
	margin: 15px 0;
}

.vds-news-teaser-kachel.row,
.vds-news-teaser-kachel-side.row {
	margin-bottom: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.vds-news-teaser-kachel .vds-news-teaser-single * {
		word-wrap: none;
		word-break: keep-all;
	}
}
@supports (-ms-ime-align:auto) {
	.vds-news-teaser-kachel .vds-news-teaser-single * {
		word-wrap: none;
		word-break: keep-all;
	}
}

.grid-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

@supports (display: grid) {
	.grid-container {
		display: grid;
		grid-column-gap: 30px;
		grid-row-gap: 30px;
	}

	@media (min-width: 576px) {
		.grid-container {
			grid-template-columns: repeat(2, 1fr);
		}

		.vds-news-teaser-double {
			grid-column-start: 1;
			grid-column-end: 3;
			grid-row-start: 1;
			grid-row-end: 3;
		}
	}

	@media (min-width: 768px) {
		.grid-container {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media (max-width: 575px) {
		.grid-container {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}

.ie11 div {
	margin-right: -30px;
}

.ie11 .kachelarrow svg {
	width: 28px;
}

@media (min-width: 576px) {
	.ie11 .vds-news-teaser-double {
		width: calc(50% - 30px);
	}

	.ie11 .vds-news-teaser-single {
		width: calc(50% - 30px);
	}
}

@media (min-width: 768px) {
	.ie11 .vds-news-teaser-double {
		width: calc(66.6% - 30px);
	}

	.ie11 .vds-news-teaser-single {
		width: calc(33.3% - 30px);
	}
}

@media (max-width: 575px) {
	.ie11 .vds-news-teaser-double {
		width: calc(100% - 30px);
	}

	.ie11 .vds-news-teaser-single {
		width: calc(100% - 30px);
	}
}

.ie11 .vds-news-teaser-single,
.ie11 .vds-news-teaser-double {
	margin-bottom: 30px;
	margin-right: 30px;
}

.vds-news-teaser-single,
.vds-news-teaser-double {
	background-color: #2598d5;
	position: relative;
}

.vds-news-teaser-single {
	height: auto;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	flex-direction: column;
}

.vds-news-teaser-double .kachelarrow,
.vds-news-teaser-single .kachelarrow {
	position: absolute;
	bottom: 6px;
	right: 10px;
	text-align: right;
}

.vds-news-teaser-single img.img-fluid,
.vds-news-teaser-double img.img-fluid {
	min-width: 100%;
	max-width: 100%;
	height: auto;
}

.vds-news-teaser-single img,
.vds-news-teaser-double img {
	height: 100%;
}

.vds-news-teaser-single span:first-of-type {
	font-size: 12pt;
	margin: 22px 22px 0 22px;
}

.vds-news-teaser-double span:last-child,
.vds-news-teaser-single span:last-child {
	margin-bottom: 11px;
	margin-right: 20px;
}

.vds-news-teaser-single h1 {
	font-size: 19px;
	line-height: 33px;
	margin: 5px 22px 40px 22px;
}

.vds-news-teaser-single h2 {
	font-size: 19px;
	line-height: 33px;
}

.vds-news-teaser-single img:not(.img-fluid),
.vds-news-teaser-single svg {
	float: right;
}

.vds-news-teaser-double {
	height: auto;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	flex-direction: column;
}

.vds-news-teaser-double span:first-of-type {
	font-size: 24pt;
	color: #fff;
	line-height: 60px;
	text-align: center;
	display: block;
	position: relative;
	bottom: 28px;
	background-color: #2598d5;
	height: 60px;
	width: 211px;
}

.vds-news-teaser-double h1 {
	font-size: 12pt;
	line-height: normal;
	margin: 22px 30px 0 30px;
}

.vds-news-teaser-double h2 {
	font-size: 20pt;
	line-height: normal;
	margin: 10px 30px 40px;
}

.vds-news-teaser-single img:not(.img-fluid),
.vds-news-teaser-double img:not(.img-fluid),
.vds-news-teaser-single svg,
.vds-news-teaser-double svg {
	height: 15px;
}

.vds-news-teaser-single h1,
.vds-news-teaser-single h2,
.vds-news-teaser-single span,
.vds-news-teaser-double h1,
.vds-news-teaser-double h2 {
	color: #fff;
}

@media (min-width: 576px) {
	.vds-news-teaser-double {
		border-bottom-right-radius: 2rem;
	}

	.vds-news-teaser-double img.img-fluid {
		margin-bottom: -60px;
	}

	.vds-news-teaser-double span:last-child {
		margin: 40px 30px;
		position: absolute;
		bottom: 0;
		right: 0;
	}
}

@media (max-width: 575px) {
	.vds-news-teaser-double span:first-of-type {
		display: none;
	}
	.vds-news-teaser-double {
		margin: 15px 0;
	}
	.vds-news-teaser-double h1 {
		font-size: 19px;
		line-height: 22px ;
		margin-bottom: 12px;
	}
	.vds-news-teaser-double h2 {
		font-size: 12pt;
	}
	.vds-news-teaser-double img:not(.img-fluid) {
		/*float: right;*/
		margin: 0 30px 15px 30px;
	}
	.vds-news-teaser-single img:not(.img-fluid) {
		bottom: 57px;
	}
}

.vds-news-teaser-button {
	background-color: #2598d5;
	width: 226px;
	height: 59px;
	margin: 45px auto;
}

.vds-news-teaser-button span {
	font-size: 14pt;
	line-height: 59px;
	margin-left: 25px;
	color: #fff;
}

.vds-news-teaser-button img {
	height: 15px;
	transform: rotate(90deg);
	position: relative;
	margin-top: -5px;
	left: 40px;
}


/*
 * VDS NEWS LIST
 */
.vds-news-list {
    padding: 0 15px;
}

.vds-news-list .mobileHide {
	margin-left: -15px;
}

.vds-news-list-single {
    border-top: 1px solid #dfe1e3;
    padding-top: 20px;
}

.vds-news-list-single.row {
    margin-bottom: 0;
}

.vds-news-list .col-3,
.vds-news-list .col-9 {
	padding: 0;
}

.vds-news-list-date img,
.vds-news-list-date svg {
	height: 15px;
}

.vds-news-list-date span {
	font-size: 19px;
	line-height: normal;
}

.vds-news-list-text h1,
.vds-news-list-text h2,
.vds-news-list-text span {
	font-size: 16px;
	line-height: 20px;
}

.vds-news-list-date span.vds-news-list-date-y,
.vds-news-list-text h1 {
	color: #6c6e6f;
}

.vds-news-list-date span.vds-news-list-date-md,
.vds-news-list-text h2,
.vds-news-list-text span {
	color: #003d90;
}

.vds-news-list-date span.vds-news-list-date-md,
.vds-news-list-date span.vds-news-list-date-y {
	display: block;
}



.vds-news-list-text a {
    text-decoration: none;
}

.vds-news-list-text h1,
.vds-news-list-text h2 {
	margin-bottom: 16px;
	margin-right: 15px;
}

@media (max-width: 767px) {
	.vds-news-list-single.mobileHide,
	.vds-news-list h3.mobileHide,
	.vds-news-list article:nth-of-type(3),
	.vds-news-list article:nth-of-type(4),
	.vds-news-list article:nth-of-type(5) {
		display: none;
	}
	/*
	.vds-news-list {
		margin-top: 60px;
	}
	*/
	.vds-news-list-single:last-child {
		border-bottom: 1px solid #dfe1e3;
		padding-bottom: 20px;
	}
}

@media (min-width: 768px) {
	.vds-news-list .mobileHide {
		margin-top: -43px;
		margin-bottom: 9px;
	}
}