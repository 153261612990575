.content ul:not(.breadcrumb) {
    list-style: none;
    padding: 0;
}

.content ul:not(.breadcrumb) li {
    margin-bottom: 10px;
    padding-left: 30px;
}
.content ul:not(.breadcrumb) li:before {
    content: "";
    display: inline-block;
    margin-left: -35px;
    width: 35px;
    height: 25px;
    background: #003d90;
	border-left: 10px solid #fff;
	border-bottom: 1px solid #fff;
	border-top: 15px solid #fff;
	border-right: 16px solid #fff;
}

html {
    scroll-behavior: smooth;
}


a.arrowlink:before {
    content: url("../Icons/Pfeile/Pfeil_Lang_Grau.svg");
    width: 30px;
    height: 20px;
    display: inline-block;
    margin-right: 20px;
}

#content a.arrowlink:hover {
    text-decoration: none;
}

a.arrowlink:hover:before {
    content: url("../Icons/Pfeile/Pfeil_Lang_Hellblau.svg");
}

.separator{
    height: 80px;
}


.contenttable {
    width: 100%;
}

.contenttable tr td {
    padding: 10px 0;
    border-top: 1px solid #dddddd;
    vertical-align: top;
}
.contenttable tr td ul li {
    line-height: 16px;
}


:target:before {
    content: "";
    display: block;
    height: 100px; /* same as your fixed header height */
    margin: -100px 0 0; /* negative fixed header height */
}

.tx_vdspublikationen .table {
    margin-top: 70px;
}
.tx_vdspublikationen .table thead {
    background-color: #007ec3;
    color: #fff;
}
.tx_vdspublikationen .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255,255,255,1);
}

.tx_vdspublikationen .table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(224,239,248,1);
}



ul.tx-indexedsearch-browsebox {
    list-style: none;
    display: inline-block;
    margin: 20px 0 40px 0;
    padding: 0;
}



ul.tx-indexedsearch-browsebox li {
    display: inline-block;
    margin: 0px;
    padding: 0;
    border: 1px solid #e0e2e3;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
}
#content ul.tx-indexedsearch-browsebox li a{
    padding: 5px 20px;
    display: inline-block;
}

#content ul.tx-indexedsearch-browsebox li a:hover {
    background-color: #e0e2e3;
}

ul.tx-indexedsearch-browsebox li:last-child {
    border-right: 0;
    color: #ffffff;
    background-color: #52ade2;
}
#content ul.tx-indexedsearch-browsebox li:first-child a,
#content ul.tx-indexedsearch-browsebox li:last-child a {
    color: #ffffff;
}


ul.tx-indexedsearch-browsebox li:first-child {
    background-color: #52ade2;
    color: #ffffff;
}

#content ul.tx-indexedsearch-browsebox li.tx-indexedsearch-browselist-currentPage a {
    color: grey;
}

#content  ul.tx-indexedsearch-browsebox li:first-child a {
    color: #ffffff;
}

.tx-indexedsearch-redMarkup {
    color: #42a6db;
}

.tx-indexedsearch-res {
    border-bottom: 1px solid #dfe1e3;
    margin-bottom: 40px;
    padding-bottom: 10px;
    color: #212529;
}
#content .tx-indexedsearch-res h3{
    margin-bottom: 10px;
}
#content .tx-indexedsearch-res h3 span.tx-indexedsearch-title a {
    color: #212529;
}
#content .tx-indexedsearch-res h3 span.tx-indexedsearch-title a:hover {
    color: #42a6db;
    text-decoration: none;
}

.frame-type-form_formframework select option {
    font-family: "Din Pro Regular", sans-serif;
    background-color: #eeeeee;
    color: #212529;
}

#content .approvalattribute table {
    border-collapse: collapse;
}
#content .approvalattribute table,
#content .approvalattribute th,
#content .approvalattribute td {
    border: 1px solid black;
    text-align: right;
    padding: 0 10px;
}

.cc-window.cc-floating {
    max-width: 100% !important;
}

.form-group .radio {
    display: inline-block;
    width: auto;
    margin-right: 40px;
}