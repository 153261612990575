/*
 * VDS Download Box
 */
.vds-download-box .row {
	width: 100%;
	border: 1px solid #2598d5;
	margin: 0;
}

.vds-download-box img.download-box-icon {
	width: 26px;
	margin-top: -14px;
	margin-left: 20px;
}


@media (max-width: 767px) {
	.vds-download-box img.download-box-icon {
		width: 20px;
		margin-top: -3px;
		margin-left: 10px;
	}
}


.vds-download-box .vds-download-box-header h2 {
	color: #6c6e6f;
	margin: 16px 0;
	font-size: 19px; /*line-height: 33px;*/
	line-height: normal;
}

.vds-download-box-header,
.vds-download-box-link {
	align-items: center;
	display: flex;
}

.vds-download-box .row .vds-download-box-header {
	min-height: 120px;
	margin: 0 13px;
}

.vds-download-box-header.open,
.vds-download-box-header.closed {
	height: 100%;
}

.vds-download-box-link {
	margin: 20px 12px;
}

.vds-download-box-link-text {
	margin: 10px 12px;
}

.vds-download-box-link a {
	text-decoration: none;
	cursor: pointer;
	color: #2598d5;
}
.vds-download-box-link p {
	width: 100%;
}

.vds-download-box {
	font-size: 19px;
	line-height: 26px;
	margin: 60px 0;
}

@media (max-width: 767px) {
	.vds-download-box {
		margin: 20px 0;
	}
}

.vds-download-box,
.vds-download-box h1 {
	color: #2598d5;
}

.vds-download-box h1 {
	display: inline-block;
	font-size: 28px;
	line-height: 35px;
}


.vds-download-box-line {
	border-bottom: 1px solid #dfe1e3;
	margin: 0 15px;
}

@media (max-width: 767px) {
	.vds-download-box .row .vds-download-box-header {
		min-height: 60px;
	}
	.vds-download-box .row.mobileHide .col-12,
	.vds-download-box .row.mobileHide :not(.d-block) .vds-download-box-header,
	.vds-download-box .row.mobileHide :not(.d-block) .vds-download-box-line {
		display: none;
	}
	/*
	.vds-download-box .row :not(.d-block) .vds-download-box-line {
		display: none;
	}
	*/
	.vds-download-box .row:not(.mobileHide) .d-block .vds-download-box-header {
		border-bottom: 1px solid #dfe1e3;
	}
	.vds-download-box .row:not(.mobileHide) .d-block {
		padding-bottom: 39px;
	}
	.vds-download-box-link {
		margin: 20px 13px;
	}
	.vds-download-box-arrow {
		width: 24px;
		margin: auto 0 auto auto;
		cursor: pointer;
	}
	.vds-download-box-header:not(.closed) .closed,
	.vds-download-box-header:not(.open) .open {
		display: none;
	}
}