/*
 * VDS x COL SLIDER & QUOTE SLIDER CROSSOVER
 */
@media (max-width: 1250px) and (min-width: 992px) {
    .vds-x-col-slider.row.slick-slider{
        margin: 0 45px 100px;
    }
}

.vds-x-col-slider .slick-prev.slick-arrow,
.vds-x-col-slider .slick-next.slick-arrow {
    height: 50%;
    position: absolute;
	width: 25px;
    top: 0;
	border: none;
	background: none;
	padding: 0;
	margin-left: -10px;
	margin-right: -10px;
}


.vds-x-col-slider .slick-prev.slick-arrow {
    left: -30px;
}

.vds-x-col-slider .slick-next.slick-arrow {
    right: -30px;
}

.vds-x-col-slider.row {
    padding-bottom: 0;
    margin-bottom: 100px;
}

.vds-x-col-slider .img-fluid {
    width: 100%;
}

.vds-x-col-slider-text {
	padding: 30px;
}

.vds-x-col-slider .slick-slide{
    background-color: #2598d5;
    margin: 0 15px;
	border-bottom-right-radius: 2rem;
    padding:0;
}

.vds-x-col-slider-text:only-child {
    height: 100%;
}

.vds-x-col-slider-text .vds-x-col-slider-link img,
.vds-x-col-slider-text .vds-x-col-slider-link svg {
	width: 25px;
}

.vds-x-col-slider-text h1 {
	font-size: 28px; line-height: 35px;
}

@media (max-width: 767px) {
	.vds-x-col-slider-text h1 {
		margin-bottom: 1rem;
	}
}
@media (min-width: 768px) {
	.vds-x-col-slider-text h1 {
		margin-bottom: 2rem;
	}
}

.vds-x-col-slider-text h2 {
	font-size: 16px;
    line-height: 22px;
}

.vds-x-col-slider-text,
.vds-x-col-slider-text h1,
.vds-x-col-slider-text h2,
.vds-x-col-slider-text p {
    color: #fff;
}

.vds-x-col-slider-text p {
    font-size: 16px;
    line-height: 23px;
	margin-bottom: auto;
}

.vds-x-col-slider-text p:last-of-type {
    margin-bottom: 3rem;
}

/* Dots */
.vds-x-col-slider .slick-dotted.slick-slider {
    margin-bottom: 70px;
}

.vds-x-col-slider .slick-dots {
    position: absolute;
	bottom: -60px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.vds-x-col-slider .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px !important;
    padding: 0 !important;
    cursor: pointer;
}
.vds-x-col-slider .slick-dots li::before  {
	background: none !important;
	margin: 0 !important;
}
.vds-x-col-slider .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.vds-x-col-slider .slick-dots li button:hover,
.vds-x-col-slider .slick-dots li button:focus {
    outline: none;
}
.vds-x-col-slider .slick-dots li button:hover:before,
.vds-x-col-slider .slick-dots li button:focus:before,
.vds-quote-slider .slick-dots li button:hover:before {
    opacity: 1;
}
.vds-x-col-slider .slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '';
	text-align: center;
	border: 2px solid #2598d5;
	border-radius: 10px;
}
.vds-x-col-slider .slick-dots li.slick-active button:before {
	border: 10px solid #2598d5;
	border-radius: 10px;
}