 /*
 * MESSE TEASER
 */
#content .vds-messe-teaser {
	background-color: #f0f1f1;
	color: #6c6e6f;
	margin: 0 0 21px 0;
	border-bottom-right-radius: 2rem;
}

 #content  .vds-messe-teaser:hover {
	text-decoration: none;
	 color: #6c6e6f;
}

.vds-messe-teaser .col-1,
.vds-messe-teaser .col-2,
.vds-messe-teaser .col-3,
.vds-messe-teaser .col-4,
.vds-messe-teaser .col-6,
.vds-messe-teaser .col-7,
.vds-messe-teaser .col-8,
.vds-messe-teaser .col-9 {
	padding: 0;
}


.vds-messe-teaser .vds-messe-teaser-text {
	margin: auto 0;
	padding-right: 10px;
}

.vds-messe-teaser .vds-messe-teaser-text p{
	font-size: 16px; line-height: 22px;
	/*line-height: 16px;*/
	margin: 4px 0;
	text-decoration: none;
}

.vds-messe-teaser .vds-messe-teaser-text img{
	height: 15px;
	width: auto;
	margin-bottom: 5px;
}

.vds-messe-teaser .vds-messe-teaser-img img{
	height: auto;
	width: 100%;
}



@media (min-width: 576px) {
    .vds-messe-teaser .vds-messe-teaser-text img{
		display: none;
	}
}

@media (min-width: 1146px) {
    .vds-messe-teaser p {
		/*line-height: normal;*/
	}
}

@media (max-width: 767px) {
    .vds-messe-teaser img {
		width: auto;
	}
}

@media (-ms-high-contrast: active),(-ms-high-contrast: none) {
	.vds-messe-teaser .vds-messe-teaser-text {
		margin-top: 20px;
	}
}