
.loader-container {
  min-height: 600px;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    margin-left: 15px;
  }
}

.loader {
  width: 120px;
  height: 120px;
  display: block;
  position: relative;
  background: url('../.vds/Public/Images/vds-logo-de.jpg');
  box-sizing: border-box;
  animation: flipX 1s linear infinite;
}

@keyframes flipX {
  0% {
    transform: perspective(200px) rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: perspective(200px) rotateX(-360deg) rotateY(0deg);
  }
}
   