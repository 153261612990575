// Import bootstrap core
@import './shop-variables.scss';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';


// Import vds customizing (Die Medialen)
@import '../.vds/Public/Css/animate.min.css';
@import '../.vds/Public/Css/messeTeaser.css';
@import '../.vds/Public/Css/textMediaOneTwo.css';
@import '../.vds/Public/Css/tabBox.css';
@import '../.vds/Public/Css/accordionElement.css';
@import '../.vds/Public/Css/tabPointNavigationElement.css';
@import '../.vds/Public/Css/desktopCarousel.css';
@import '../.vds/Public/Css/vdsTopThema.css';
@import '../.vds/Public/Css/vdsBewerbung.css';
@import '../.vds/Public/Css/vdsBildungspass.css';
@import '../.vds/Public/Css/vdsButton.css';
@import '../.vds/Public/Css/vdsContacts.css';
@import '../.vds/Public/Css/vdsCoverflow.css';
@import '../.vds/Public/Css/vdsDownloadAnchor.css';
@import '../.vds/Public/Css/vdsDownloadBox.css';
@import '../.vds/Public/Css/vdsQuicklinksBox.css';
@import '../.vds/Public/Css/vdsDownloadButton.css';
@import '../.vds/Public/Css/vdsStellenangebot.css';
@import '../.vds/Public/Css/vdsSuche.css';
@import '../.vds/Public/Css/vdsTeaser.css';
@import '../.vds/Public/Css/vdsThreeColSlider.css';
@import '../.vds/Public/Css/style.css';
@import '../.vds/Public/Css/slider.css';
@import '../.vds/Public/Css/slick.css';
@import '../.vds/Public/Css/swiper.min.css';
@import '../.vds/Public/Css/news.css';
@import '../.vds/Public/Css/newsDetail.css';
@import '../.vds/Public/Css/navigation.css';
@import '../.vds/Public/Css/video.css';
@import '../.vds/Public/Css/vdsXColSlider.css';
@import '../.vds/Public/Css/tim.css';
@import './loader.scss';
@import './header-animation.scss';
@import './progressbar.scss';
@import './autosuggest.scss';

img.vds-cover {
	border: $border-width solid $border-color;
}

a.white, a.white:hover {
	color: #fff !important;
	text-decoration: none;
}

a.vds-button, button.vds-button {
	display: inline-block;
	border: none;
	outline: none;
	//padding: 1rem 2rem;
	margin: 0;
	text-decoration: none;
	//background: #0069ed;
	color: #ffffff;
	font-family: sans-serif;
	cursor: pointer;
	text-align: left;
	transition: background 250ms ease-in-out, 
	            transform 150ms ease;
	-webkit-appearance: none;
	-moz-appearance: none;
	min-height:90px;

	.vds-button-text {
		font-size: 1.3rem;
		&:hover {
			text-decoration: none;
		}
	}

	.vds-button-img {
		stroke: #fff;
		width: auto;
		height: auto;
	}

	&:hover:enabled {
	}

	&:hover:disabled {
		.vds-button-text {
			color: #0056b3;
		}
		background-color: #dfe1e3;
	}

	&.green {
		background-color: $success;
		.vds-button-text {
			color: #fff;
		}
	}

	&.green:hover {
		background-color: lighten($success, 7.5%);
	}

	&:disabled {
		opacity: 0.5;
		cursor: default;
	}

	&:active {
		//transform: scale(0.99);
	}
}

.carousel-caption {
	right:0;
	left:0;
	bottom: 50px;
	padding: 0 20px;

	h3 {
		font-size: 2.5rem;
		font-weight: bold;
		color: #fff;
	}

	p {
		font-size:1.8rem;
	}
}

.navbar-toggler {
	svg {
		stroke: #003C8D;
	}
}

.frame {
	margin-top: 160px; 
}

.carousel-control-prev, .carousel-control-next {
	z-index: 100;
}

.carousel-indicators {
	li {
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
		content: '';
		text-align: center;
		border: 2px solid #2598d5;
		border-radius: 50%;
	}

	bottom: 0;
}

.vds-card {
	border-radius: 0 0 15px 0;
	border: 0px solid;
	background-color: #52ade2;
	//min-height: 580px;
	color: #fff;

	.vds-card-img {
		margin: 2px;
		img {
			border-radius:0;
		}
	}

	h5 {
		color:#fff;
		font-size: 28px;
		line-height: 35px;
	}
}

li.icon-append {
	position:relative;
}

i.bubble {
	background-color: red;
	font-style: normal;
	width:17px;
	height:17px;
	padding:2px;
	border-radius: 45%;
	display: block;
	position: absolute;
	color: #fff;
	font-size: 10px;
	text-align: center;
	vertical-align: middle;
	line-height: 14px;
	top: 5px;
	right: -3px;
	font-weight: medium;
}

.text-shadow {
  text-shadow: black 0.1em 0.1em 0.2em;
}

.pinboard-dropdown {
	.dropdown-menu {
		background-color: $white !important;
		padding-bottom: 10px !important;
		max-width: 350px;
	}
}