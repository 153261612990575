/*
 * VDS BUTTON
 */
.vds-button{
	background-color: #dfe1e3;
	border-bottom-right-radius: 2rem;
	display: inline-block;
	height: auto;
	min-height: 120px;
	padding: 0;
	position: relative;
	width: 100%;
}

#content a.vds-back-button {
	background-color: #dfe1e3;
	border-bottom-right-radius: 2rem;
	display: inline-block;
	height: auto;
	min-height: 60px;
	padding: 0;
	position: relative;
	width: 80%;
	line-height: 60px;
	text-align: center;
}
#content a.vds-back-button:hover {
	background-color: #2598d5;
	text-decoration: none;
	color: #ffffff;
}


form .vds-button {
	border: none;
	cursor: pointer;
	min-height: 42px;
	min-width: 160px;
	transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}

form .vds-button:visited {
	background-color: #0056b3;
}

.vds-button.small {
	width: 83%;
}

.vds-button:hover,
.vds-button.active{
	background-color: #2598d5;
}

.vds-button:hover,
.vds-button:hover .vds-button-text,
.vds-button.active .vds-button-text {
	color: #fff;
}

.vds-button .vds-button-text {
	color: #0056b3;
	font-family: 'DinProRegular', sans-serif;
	font-size: 28px;
	line-height: 35px;
	margin: 16px 30px 40px 24px;
}

.vds-button .vds-button-img {
	bottom: 0;
	left: 0;
	position: absolute;
    height: 15px;
    margin-bottom: 13px;
    margin-left: 24px;
    width: 25px;
}

@media (max-width: 767px) {
	.vds-button .vds-button-text {
		color: #0056b3;
		font-size: 19px;
		line-height: 33px;
		margin-left: 12px;
		margin-top: 20px;
	}

	.vds-button{
		margin-bottom: 40px;
	}

	.vds-button .vds-button-img {
		bottom: 0;
		margin-bottom: 13px;
		margin-left: 13px;
		position: absolute;
	}
}
