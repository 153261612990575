.accordion-element-full,
.accordion-element-plus{
	margin: 30px 0;
}
.accordion-element-full header,
.accordion-element-plus header{
	margin-bottom: 30px;
}
.accordion-element-full h3,
.accordion-element-plus h3{
	font-size: 26px;
	line-height: normal;
}
.accordion-element-full .accordion-element-card,
.accordion-element-plus .accordion-element-card{
	margin-bottom: 20px;
}
.accordion-element-full .accordion-element-card img,
.accordion-element-plus .accordion-element-card img{
	float: left;
	max-width: 35%;
	margin-right: 5%;
	margin-top: 8px;
	margin-bottom: 10px;
}

@media (max-width: 575px) {
	.accordion-element-full .accordion-element-card img,
	.accordion-element-plus .accordion-element-card img{
		display: none;
	}

	.accordion-element-full h3,
	.accordion-element-plus h3{
		font-size: 20px;
		line-height: normal;
	}

}

.accordion-element{
	min-height: 65px;
}

.accordion-element-full .card-body,
.accordion-element-plus .accordion-element-card.active{
	margin-bottom: 0px;
	margin-top: 15px;
}
.accordion-element-full .card-header{
	display: block;
	border-radius: 0;
	border-bottom-right-radius: 30px;
}
.accordion-element-full .card-header{
	background-color:#2699d6;
	padding: 20px 1.25rem;
}
.accordion-element-full a.card-header,
.accordion-element-full a.card-header h3{
	color:#ffffff;
	text-decoration: none;
	font-family: "DinProMedium";
}
.accordion-element-full .card-header.collapsed{
	background-color:#dfe1e3;
}
.accordion-element-full a.card-header.collapsed,
.accordion-element-full a.card-header.collapsed h3{
	color:#003e90;
	text-decoration: none;
	font-family: "DinProMedium";
}
.accordion-element-full a.card-header:hover,
.accordion-element-full a.card-header:hover h3{
	color:#ffffff;
	background-color:#2699d6;
}
.accordion-element-plus .card-header{
	border-radius: 0;
	border-bottom-right-radius: 30px;
}
.accordion-element-plus .card-header{
	color:#003e90;
	background-color:#dfe1e3;
	padding: 20px 1.25rem;
}

.accordion-element-plus .active .card-header{
	color:#ffffff;
	background-color:#2699d6;
}
.accordion-element-plus .card-header a,
.accordion-element-plus .card-header a:hover{
	color:#003e90;
	text-decoration: none;
	font-family: "DinProMedium";
}
.accordion-element-plus .active .card-header a,
.accordion-element-plus .active .card-header a:hover{
	color:#ffffff;
	text-decoration: none;
	font-family: "DinProMedium";
}
.accordion-element-plus .mb-0,
.accordion-element-full .mb-0 {
    position: relative;
    padding-right: 40px;
    word-break: keep-all;
}
.accordion-element-plus .cicon,
.accordion-element-full .cicon {
    position: absolute;
    right: 0;
    height: 100%;
    display: inline-block;
}
.accordion-element-plus i.cicon,
.accordion-element-full i.cicon {
    position: relative;
    display: inline-block;
    font-family: none;
    font-style: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 32px;
    text-align: center;
}
.accordion-element-plus .cicon,
.accordion-element-full .cicon {
	border-color:#ffffff;
}
.accordion-element-plus .collapsed .cicon,
.accordion-element-full .collapsed .cicon {
	border-color:#003e90;
}
.accordion-element-plus .more-less,
.accordion-element-full .more-less {
	color: #212121;
}
.accordion-element-plus .cicon-plus-minus,
.accordion-element-full .cicon-plus-minus {
	width: 30px;
	height: 30px;
	background: url("../Icons/Dot_Plus_Minus/Dot_Minus_Weiss_Mobile.svg");
    background-repeat: no-repeat;
    background-position: center;
}
.accordion-element-plus .active:hover .cicon-plus-minus,
.accordion-element-full .collapsed:hover .cicon-plus-minus{
	background: url("../Icons/Dot_Plus_Minus/Dot_Plus_Weiss_Mobile.svg");
    background-repeat: no-repeat;
    background-position: center;
}
.accordion-element-plus .active .cicon-plus-minus,
.accordion-element-full .collapsed .cicon-plus-minus {
	background: url("../Icons/Dot_Plus_Minus/Dot_Plus_Dunkelblau_Mobile.svg");
    background-repeat: no-repeat;
    background-position: center;
}
.accordion-element-full .card-body,
.accordion-element-plus .card-body {
	padding: 10px 10px 1.25rem 0;
}