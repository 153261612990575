/*
 * 1/3 Text 2/3 Bild - Element (textmediaonetwo) 
 */

 .bild-500, .bild-500 h1, .bild-500 h2, .bild-500 h3{
	color: #ffffff;
}
.bild-500 .bild{
	padding-right: 0;
	padding-left: 0;
	min-height: 300px;
}
.bild-500 .text{
	padding: 40px 60px 40px 60px;
	text-align: center;
}

.bild-500 .text .stretch_headers{
	border-top: 10px solid #ffffff;
	width: 100%;
	max-width: 250px;
	margin: 0 auto;
 }

.bild-500 .text h1 {
	font-size: 84px;
	line-height: 75px;
	font-family: "DinProMedium";
	margin-bottom: 0;
}
.bild-500 .text h2 {
	font-size: 38px;
	margin-bottom: 0;
	text-transform: uppercase;
}

.bild-500 .text h1 span,
.bild-500 .text h2 span {
	margin-top: 10px;
	display: inline-block;
}

.bild-500 .text p {
	font-family: "DinProRegular";
	font-size: 18px;
	line-height: 1.7em;
	display: inline-block;
	margin-top: 15px;
}
.bild-500{
	margin: 30px 0;
}

.bild-500 .text span.stretch_it{
	white-space: nowrap;
	display: inline-block;
}

.justify{ text-align:justify; }



@media (min-width: 768px) {
	.bild-500 .text{
		text-align: left;
		padding: 40px 30px 40px 30px;
	}

	.bild-500 .text .stretch_headers{
		max-width: 350px;
		margin: 0;
	}
}
@media (min-width: 900px) {
	.bild-500 .text{
		text-align: left;
		padding: 40px 60px 40px 60px;
	}
	.bild-500 .text .stretch_headers{
		width: 80%;
	}
}


@media (min-width: 1140px) {
	.bild-500 .text h1{
		font-size: 114px;
		line-height: 100px;
	}
	.bild-500 .text h2{
		font-size: 50px;
		line-height: 60px;
	}
	.bild-500 .text p{
		font-size: 18px;
	}
}


@media all and (-ms-high-contrast:none)
{
	*::-ms-backdrop, .bild-500 .text h2{ padding-top: 8px; } /* IE11 */
	*::-ms-backdrop, .bild-500 .text h1{ line-height: 85px; padding-top: 15px; } /* IE11 */
}
