/*
 * VDS Download Box Anchor
 */
#publications-box-anchor,
#quicklinks-box-anchor,
#download-box-anchor,
#box-anchor {
	margin-top: 60px;
	height: 59px;
	background-color: #dfe1e3;
	display: flex;
}

#publications-box-anchor,
#download-box-anchor {
	margin-top: 20px;
}

#box-anchor {
	margin-top: 0;
}

#content #publications-box-anchor a,
#content #quicklinks-box-anchor a,
#content #download-box-anchor a, 
#content #box-anchor a {
	text-decoration: none;
	font-size: 17px;
	color: #0056b3;
	width: 100%;
	line-height: 17px;
	font-family: 'DinProMedium';
}

@media (min-width: 768px) {
	#publications-box-anchor a,
	#quicklinks-box-anchor a,
	#download-box-anchor a,
	#box-anchor a {
		margin: auto calc(22px - ((1146px - 100vw) / 378 * 14));
	}
}

@media (min-width: 1146px) {
	#publications-box-anchor a,
	#quicklinks-box-anchor a,
	#download-box-anchor a,
	#box-anchor a {
		margin: auto 22px;
	}
}

#publications-box-anchor a img,
#publications-box-anchor a svg,
#quicklinks-box-anchor a img,
#quicklinks-box-anchor a svg,
#download-box-anchor a img,
#download-box-anchor a svg,
#box-anchor a svg {
	width: 23px;
	/*margin-left: 37px;*/
}

#publications-box-anchor a .row .col,
#publications-box-anchor a .row .col-auto,
#quicklinks-box-anchor a .row .col,
#quicklinks-box-anchor a .row .col-auto,
#download-box-anchor a .row .col,
#download-box-anchor a .row .col-auto,
#box-anchor a .row .col,
#box-anchor a .row .col-auto {
	padding: 0;
	margin: auto;
}

#publications-box-anchor a .row,
#quicklinks-box-anchor a .row,
#download-box-anchor a .row,
#box-anchor a .row {
	margin: 0;
}


@media (-ms-high-contrast: active),(-ms-high-contrast: none) {
	#publications-box-anchor a .row,
	#quicklinks-box-anchor a .row,
	#download-box-anchor a .row,
	#box-anchor a .row {
		height: 100%;
		align-items: center;
	}
}