/*
 * VDS TEASER
 */
.vds-teaser {
	color: #003d90;
	margin-bottom: 2rem;
}

#content .vds-teaser a {
    text-decoration: none;
    color: #ffffff;
}
#content .vds-teaser a:hover {
    text-decoration: none;
    color: #003e90;
}

.vds-teaser .vds-teaser-image {
    width: 100%;
    height: 229px;
}
.vds-teaser .vds-teaser-link {
    width: 70%;
    height: 43px;
    background-color: #2598d5;
    border-bottom-right-radius: 15px;
    display: flex;
    align-items: center;
    margin: 11px 0;
    padding-left: 10px;
    color: #fff;
    text-decoration: none;
}
.vds-teaser .vds-teaser-link img,
.vds-teaser .vds-teaser-link svg {
    height: 15px;
    margin: 0 17px 0 auto;
}