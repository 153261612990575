.news .article h1{
	font-size: 19px;
	line-height: 33px;
}
.news .article .newsheadline h2{
	font-size: 80px;
	line-height: 95px;
}
.news .article :not(.newsheadline) h2{
	font-size: 34px;
	line-height: 50px; 
}
.news .article > .footer{
	display: none;
}
.news .article .article-spacer{
	margin-bottom: 70px;
}
.news .article .nav{
	margin-top: 90px;
}
.news .article .nav .inner{
	text-align: center;
	position: relative;
}
.news .article .nav .inner > div{
	display: inline-block;
}
.news .article .nav .inner div.previous{
	position: absolute;
	left: 15px;
}
.news .article .nav .inner div.next{
	position: absolute;
	right: 15px;
}
.news .article .nav a{
	text-decoration: none;
	color: #003e90;
}
.news .article .nav a:hover{
	color: #003e90;
}
.news .article .nav a span{
	color: #6c6e6f;
}
.news .article .frame-type-image{
	margin: 60px 0;
}