/*************************************************
 * Carousel/slider
 */

.vds-quote-slider {
    font-family: "DinProRegular", sans-serif;
	margin-bottom: 1.6em;
	margin: 0;
}

.vds-quote-slider .row {
	margin-bottom: 0;
}

.vds-quote-slider-top .col-4,
.vds-quote-slider-top .col-12 {
	padding: 0;
}

.vds-quote-slider .vds-quote-slider-top {
	min-height: 100px;
	background-color: #f1f1f1;
	color: #2598d5;
}

.vds-quote-slider .vds-quote-slider-top a,
.vds-quote-slider .vds-quote-slider-top a:hover,
.vds-quote-slider .vds-quote-slider-top a:focus,
.vds-quote-slider .vds-quote-slider-top a:active {
	color: #2598d5;
}

.vds-quote-slider .vds-quote-slider-bottom {
	min-height: 20px;
	background-color: #2598d5;
	color: #fff;
}

.vds-quote-slider .vds-quote-slider-bottom div {
	padding: 0;
	margin: auto 0;
}

.vds-quote-slider .vds-quote-slider-bottom div.bottom-quote{
	padding: 15px 0;
}

.vds-quote-slider .vds-quote-slider-bottom a {
	margin: auto 0;
}

.vds-quote-slider .vds-quote-slider-bottom img,
.vds-quote-slider .vds-quote-slider-bottom svg {
	height: 15px;
	margin: auto -4px auto 2rem;
}



@media (min-width: 992px) {

	.vds-quote-slider .vds-quote-slider-top {
		min-height: 375px;
	}

	.vds-quote-slider .vds-quote-slider-bottom a {
		position: relative;
		top: calc(50% - 7.5px);
	}

	.vds-quote-slider .vds-quote-slider-bottom p {
		margin: 0 2rem;
	}

	.vds-quote-slider-top .col-12 {
		margin: auto 0;
	}

	.vds-quote-slider .vds-quote-slider-top .quote-text img.openquote {
		position: absolute;
		top: 40px;
		left: -40px;
	}

	.vds-quote-slider .vds-quote-slider-top .quote-text img.closequote {
		position: relative;
		display: inline;
		right: -2px;
		top: -9px;
	}

	.vds-quote-slider .vds-quote-slider-top .quote-text {
		font-size: 24px;
		line-height: 38px;
		padding-right: 7rem;
		margin-top: 30px;
	}
}

@media (max-width: 991px) {
	.vds-quote-slider .vds-quote-slider-bottom p {
		margin: 0 13px;
	}

	.vds-quote-slider .vds-quote-slider-bottom img,
	.vds-quote-slider .vds-quote-slider-bottom svg {
		margin: 10px 0 10px 13px;
	}

	.vds-quote-slider .vds-quote-slider-bottom div.order-1.order-md-2 {
		margin-top: 9px;
	}

/*
	.vds-quote-slider-top .col-12 {
		margin-top: 0;
	}
*/
	.vds-quote-slider .vds-quote-slider-top .quote-text img.openquote {
		position: relative;
		top: 2px;
		display: inline-block;
	}

	.vds-quote-slider .vds-quote-slider-top .quote-text img.closequote {
		position: relative;
		top: -8px;
		display: inline-block;
	}

	.vds-quote-slider .slick-slide{
		margin: 0 15px;
		padding:0;
	}

	.vds-quote-slider .vds-quote-slider-top .quote-text {
		font-size: 24px;
		line-height: 38px;
		margin: 40px 40px 40px 20px;
	}
}

@media (min-width: 992px) and (-ms-high-contrast: active), (min-width: 992px) and (-ms-high-contrast: none) {
	.vds-quote-slider .vds-quote-slider-bottom {
		display: table;
		width: calc(100% + 30px);
	}

	.vds-quote-slider .vds-quote-slider-bottom div {
		display: table-cell !important; /* WEIL BOOTSTRAP */
		vertical-align: middle;
	}

	.vds-quote-slider .vds-quote-slider-bottom span.my-auto p, .vds-quote-slider .vds-quote-slider-bottom div.order-1 {
		padding: 0 2rem;
	}
}


@media (-ms-high-contrast: active),(-ms-high-contrast: none) {
	.vds-quote-slider-img {
		position: absolute;
		bottom: -420px;
		min-width: 100%;
	}
	.vds-quote-slider .vds-quote-slider-top .quote-text img:last-child {
		bottom: auto;
	}
	.vds-quote-slider .vds-quote-slider-bottom {
		display: table;
		width: calc(100% + 30px);
	}
	.vds-quote-slider .vds-quote-slider-bottom span.my-auto {
		display: table-cell;
		vertical-align: middle;
	}
}

.vds-quote-slider-img {
	position: absolute;
	bottom: 0;
	min-width: 100%;
}

.vds-quote-slider .vds-quote-slider-top .quote-text p {
	display: inline;
}

.vds-quote-slider .vds-quote-slider-top .quote-text img {
	width: 31px;
}

.vds-quote-slider .vds-quote-slider-top .quote-author {
	font-size: 18px;
	margin: 20px;
}

@media (min-width: 992px) {
	.vds-quote-slider .vds-quote-slider-top .quote-author {
		margin: 20px 0;
	}
}