/*
 * Tab-Punkt-Navigation
 */
.tab-point-nav .nav-item{
	display: block;
	margin-left: -23px;
	width: 50px;
	height: 50px;
	padding: 0;
	margin-bottom: 35px;
	border: 0px;
}

.tab-point-nav .nav-item.active{
	margin-left: -43px;
	width: 90px;
	height: 90px;
}

.tab-point-nav .nav-item.last{
	margin-bottom:0;
}

.tab-point-nav nav{
	text-align: center;
}
.tab-point-nav .nav{
	width: 3px;
    text-align: center;
    display: inline-block;
    background-color: #2699d6;
}
.tab-point-nav .tab-pane{
	border-bottom-right-radius: 30px;
	padding: 20px 55px 20px 40px;
	background-color: #f1f1f1;
	margin-top:45px;
	color: #6c757d;
}


.tab-point-nav .tab-pane.active {
	animation: fade-in 2s ease-out;
}

@keyframes fade-in {
	0% { opacity: 0.2;  }
	100% { opacity: 1; }
}
