/*
 * VDS SUCHE
 */
.vds-suche.col-12 {
    /*height: 219px;*/
	width: calc(100% - 30px);
	margin: 0;
	padding: 0;
}

.vds-suche.d-block {
	height:200px;
	padding: 0 15px;
}

.vds-suche span {
	height: 40px;
	line-height: 44px;
	font-size: 18px;
	position: absolute;
	bottom: 0;
}


    .vds-suche-1 {
		background-color: #fff;
		color: #6c6d6e;
		height: 40px;
		z-index: 6;
	}
    .vds-suche span {
		margin: auto 0 0 11px;
		font-size: 15px;
	}
	div.vds-suche-5 span {
		bottom:8px;
	}
	div.vds-suche-2 a,
	div.vds-suche-3 a,
	div.vds-suche-4 a {
		color: #fff;
	}
	div.vds-suche-1 a,
	div.vds-suche-5 a {
		color: #003e90;
	}


.vds-suche-1 {
	background-color: #fff;
	color: #6c6d6e;
	height: 40px;
	z-index: 6;
}
.vds-suche span {
	margin: auto 0 0 11px;
	font-size: 15px;
}
div.vds-suche-5 span {
	bottom:8px;
}
#content div.vds-suche-2 a,
#content div.vds-suche-3 a,
#content div.vds-suche-4 a {
	color: #fff;
}
#content div.vds-suche-1 a,
#content div.vds-suche-5 a {
	color: #003e90;
}

@media (min-width: 1095px) {
    .vds-suche span {
		margin: auto 0 0 22px;
		font-size: 16px;
	}
}
@media (min-width: 1200px) {
    .vds-suche span {
		margin: auto 0 0 33px;
		font-size: 18px;
	}
}

.vds-suche-2 {
	background-color: #75bdea;
	color: #fff;
	height: 80px;
	z-index: 5;
}

.vds-suche-3 {
	background-color: #51ade2;
	color: #fff;
	height: 120px;
	z-index: 4;
}

.vds-suche-4 {
	background-color: #2598d5;
	color: #fff;
	height: 163px;
	z-index: 3;
}

.vds-suche-5 {
	background-color: #c6c6c5;
    height: 219px;
	z-index: 2;
}

.vds-suche-2,
.vds-suche-3,
.vds-suche-4,
.vds-suche input {
	border-bottom-right-radius: 20px;
}

.vds-suche-5 {
	border-bottom-right-radius: 30px;
}

.vds-suche input {
	width: calc(100% - 30px);
	padding-right: 49px;
	height: 30px;
	margin: auto 14px 12px 12px;
	border: none;
	position: absolute;
	bottom: 0;
}

.vds-suche-1,
.vds-suche-2,
.vds-suche-3,
.vds-suche-4,
.vds-suche-5 {
	position: absolute;
	top: 0;
}

.vds-suche svg {
	height: 23px;
	width: auto;
	position: absolute;
	bottom: 16px;
	right: 28px;
	z-index: 10;
	stroke: #003C8D;
	fill: none;
	stroke-width: 2px;
}


.vds-zertifikate-box .vds-suche-4 svg {
	height: 23px;
	width: auto;
	position: absolute;
	bottom: 16px;
	right: 28px;
	z-index: 10;
	stroke: #003C8D;
	fill: none;
	stroke-width: 2px;
}