/*
 * VDS-Video
 */

/*
#header-video {
	min-height: 100vh;
	height: 100vh;
	box-sizing: border-box;
	overflow: hidden;
	position: absolute;
	width: 100%;
	z-index: -99;
}

#header-video video {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	z-index: -100;
	object-fit: cover;
}

*/
@media (max-height: 767px) {
	#header-video video  {
		min-height: 767px;
	}

	#header-video video {
		bottom: calc(100vh - 767px);
	}
}

@media all and (max-width: 768px) and (min-height: 900px){
	#header-video video  {
		bottom: calc(100vh - 900px);
	}
}


.videowrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	background-size: contain;
}

.videowrapper iframe,
.videowrapper object,
.videowrapper embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


.videooverlaytext .vds-button {
	min-height: 50px;
	width: auto;
	padding: 0 35px 0 30px;
	cursor: pointer;
	color: #213a8f;
	background-color: #ffffff;
}

.videooverlaytext {
	position: absolute;
	text-align: left;
	color:	#ffffff;
	height: 100%;
	background-color: #4a98c6;
	opacity: 0.7;
	top: 0;
	padding-left: 60px;
	padding-right: 40px;
	padding-top: 15%;
	cursor: pointer;
	-webkit-transition: opacity 1000ms;
	-moz-transition: opacity 1000ms;
	transition: opacity 1000ms;
}

@media (max-width: 575px) {
	.videooverlaytext {
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}
}


#content .videooverlaytext a,
#content .videooverlaytext a:hover {
	color: #ffffff;
	text-decoration: underline;
}

.videooverlaytext:hover {
	opacity: 0.9;
}

.videooverlayimage.hide {
	opacity: 0;
}

.youtubeframe {
	-webkit-transition: opacity 1000ms;
	-moz-transition: opacity 1000ms;
	transition: opacity 1000ms;
}

.youtubeframe.hiddenframe {
	visibility: hidden;
	opacity: 0;
}
.youtubeframe.show {
	opacity: 1;
}

.ce-textpic .video .video-embed video {
	max-width: 100%;
}



#header-video {
	min-height: 100vh;
	height: 100vh;
	box-sizing: border-box;
	overflow: hidden;
	padding-bottom: 14em;
	padding-top: 14em;
	display: table;
	background-size: cover;
	position: absolute;
	width: 100%;
	z-index: -99;
	background: url("../Images/vds_video_bg.png") #75bdea no-repeat bottom center / cover;
}

#header-video video {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	z-index: -100;
	object-fit: cover;
}


/*
@media (max-width: 600px) {
	#header-video {
		padding-top: 100px; }
}

*/

