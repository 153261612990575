.nav-bg-helper {
    background-color: #52ADE2;
    position: fixed;
    top: -140px;
    left: 0;
    right: 0;
    height: 140px;
    z-index: 5;
}
#header-top.nojs + .nav-bg-helper {
    display: none;
}
#header-top {
    background-color: white;
    height: 80px;
    box-shadow: 0 1px 5px #CCCCCC;
    border-bottom-right-radius: 2rem;
}
#navbar-top {
    max-width: 1140px;
    height: 100%;
    margin: 0 auto;
}
#navbar-top .nav-main,
#navbar-top .nav-service-secondary,
#navbar-top .nav-service-secondary .btn {
	font-size:19px;
}

.nav-open #navbar-top .nav-main, .nav-open #navbar-top .nav-service-secondary, .nav-open #navbar-top .nav-service-secondary .btn,
#navbar-top .nav-main .dropdown-menu, #navbar-top .nav-service-secondary .dropdown-menu, #navbar-top .nav-service-secondary .dropdown-menu,
.scrolled #navbar-top .nav-main, .scrolled #navbar-top .nav-service-secondary, .scrolled #navbar-top .nav-service-secondary .btn {
	font-size:16px;
}
#navbar-top .dropdown-toggle:after {
    display: none;
}
#navbar-top .icon-append img,
#navbar-top .icon-append svg {
    height: 1.5rem;
    width: 1.5rem;
    display: inline-block;
}
#navbar-top .icon-append .nav-link {
    white-space: nowrap;
    display: flex;
}
#navbar-top .navbar-brand {
    padding: 0;
}
#navbar-top .navbar-brand img {
    width: 52px;
    height: auto;
}
#navbar-top .navbar-toggler {
    border: 0;
    padding: 0;
}
#navbar-top .navbar-toggler img {
    width: 2rem;
    height: auto;
}
#navbar-top .dropdown-menu {
    background-color: #52ADE2;
    padding-bottom:60px;
}
#navbar-top ul {
    /*edge bug: https://github.com/webhintio/webhint.io/issues/210*/
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
    padding: 0;
    margin: 0;
}
#navbar-top .navbar-collapse {
    align-self: stretch;
    flex-direction: column;
}
.nav-service-container {
    display: flex;
    align-self: stretch;
}
.nav-service-container > ul, ul.nav-main {
    display: flex;
    align-items: center;
}
#navbar-top .dropdown-item .nav-link {
    color: white;
    white-space: normal;
}
#navbar-top .dropdown-item:hover, .dropdown-item:focus {
    background-color: inherit;
}
#navbar-top .dropdown-menu.second-level > .dropdown-item:hover > .nav-link,
#navbar-top .dropdown-menu.second-level > .dropdown-item:focus > .nav-link,
#navbar-top .dropdown-menu > .dropdown-item:hover > .nav-link,
#navbar-top .dropdown-menu > .dropdown-item:focus > .nav-link,
#navbar-top .nav-link,
body.nav-open #navbar-top .nav-link:hover
{
    color: #003C8D;
    font-weight: bold;
}
#navbar-top .dropdown-menu > .dropdown-item.active > .nav-link
{
    color: #003C8D;
    font-weight: bold;
}
#navbar-top .nav-link:hover
{
    color: #51aee2;
    font-weight: bold;
}

#navbar-top .nav-link.lang:hover
{
    color: #ffffff;
    font-weight: bold;
}

#navbar-top .nav-link,
#navbar-top .nav-link:hover {
	font-weight: unset;
	font-family: "DinProMedium";
}
@keyframes max-height-transition {
    from {
        max-height: 0;
    }
    to {
        max-height: 100vw;
    }
}
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
#navbar-top .open-submenu, #navbar-top .parent-item {
    cursor: pointer;
}
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.3 15.5' xml:space='preserve'%3E%3Cstyle type='text/css'%3E	.st0%7Bfill:none;stroke:%23003E90;stroke-width:1.3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cline class='st0' x1='0.6' y1='0.6' x2='27.6' y2='0.6'/%3E%3Cline class='st0' x1='0.6' y1='7.7' x2='27.6' y2='7.7'/%3E%3Cline class='st0' x1='0.6' y1='14.8' x2='27.6' y2='14.8'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
    width: 32px;
    height: 32px;
}
.navbar-light .collapse.show + .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.4 20.4' xml:space='preserve'%3E%3Cstyle type='text/css'%3E	.st0%7Bfill:none;stroke:%23003E90;stroke-width:1.3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cline class='st0' x1='0.7' y1='0.6' x2='19.7' y2='19.7'/%3E%3Cline class='st0' x1='19.7' y1='0.7' x2='0.6' y2='19.7'/%3E%3C/g%3E%3C/svg%3E");
    width: 22px;
    height: 22px;
}
#navbar-top .dropdown-item,#navbar-top .dropdown-item:hover,#navbar-top .dropdown-item:focus {
    background-color: transparent;
}
#navbar-top [class*=nav-arrow] {
    height: 1.75rem;
    width: 1rem;
    display: inline-block;
}
#navbar-top .nav-arrow-right-white {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.6 28'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23FFFFFF;stroke-width:1.3;stroke-linecap:round;stroke-linejoin:round;%7D%0A%3C/style%3E%3Cg%3E%3Cpolyline class='st0' points='0.6,27.4 10,14 0.6,0.6 '/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center;
}
#navbar-top .nav-arrow-right-blue {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.6 28'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23003E90;stroke-width:1.3;stroke-linecap:round;stroke-linejoin:round;%7D%0A%3C/style%3E%3Cg%3E%3Cpolyline class='st0' points='0.6,27.4 10,14 0.6,0.6 '/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center;
}
#navbar-top .nav-arrow-left-blue {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.6 28'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23003E90;stroke-width:1.3;stroke-linecap:round;stroke-linejoin:round;%7D%0A%3C/style%3E%3Cg%3E%3Cpolyline class='st0' points='10,0.6 0.6,14 10,27.4'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center;
}
.nav-service-secondary .dropdown-menu {
	border-radius: 0;
	border-bottom-right-radius: 2rem;
}
#subfooter a.nav-link:hover {
	color: #51aee2;
}
@media (max-width: 991px) {
    #navbar-top-content.collapsing, #navbar-top-content.show,
    #navbar-top .nav-main .dropdown-menu.show
    {
        position: fixed;
        top: 80px;
        bottom:0;
        left: 0;
        right: 0;
        background-color: #52ADE2;
        border: 0;
        display: flex;
        flex-direction: column;
    }
    #navbar-top-content.collapsing {
        opacity: 0;
    }
    #navbar-top-content.show, #navbar-top .nav-main .dropdown-menu.show {
        opacity: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 1rem 0 0 0;
        display: flex;
        border-radius: 0;
    }
    #navbar-top .desktop {
        display: none;
    }
    #navbar-top .nav-link {
        flex-grow: 1;
    }
    #navbar-top .open-submenu {
        flex-grow: 99;
        text-align: right;
    }
    #navbar-top-content, #navbar-top .nav-service-primary, #navbar-top .nav-main {
        flex-direction: column;
        align-items: stretch;
    }
    .nav-service-container, #navbar-top .nav-main {
        padding: 0 1rem;
        min-height: 9rem;
    }
    .nav-service-container {
        flex-grow: 1;
        flex-direction: column;
        background-color: #007EC3;
    }
    .nav-service-secondary {
        display: flex;
    }
    #navbar-top .dropdown-menu.show {
        padding: 1rem;
    }
    #navbar-top .nav-item:not(.icon-append), .dropdown-menu.show .dropdown-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #9FD3ED;
    }
    #navbar-top .nav-item:last-child,
    .nav-service-secondary .dropdown-menu.show .dropdown-item {
        border-bottom: 0;
    }
    #navbar-top .parent-item {
        position:relative;
        cursor: pointer;
    }
    #navbar-top .parent-item [class*=nav-arrow] {
        position:absolute;
        top:0.8rem;
        left:0.5rem;
    }
    #navbar-top .nav-item.active > .nav-link, #navbar-top .parent-item > .nav-link, #navbar-top .dropdown-item.active > .nav-link {
        color: #003E90 !important;
        font-weight: bold;
    }
    #navbar-top .nav-link {
        color: white;
        font-weight: normal;
    }
    #navbar-top-content.show, #navbar-top-content.collapsing, body:before {
        transition: opacity .15s ease-out;
    }
    #navbar-top-content.show .nav-service-secondary {
        animation: fade-in .125s;
    }
    #navbar-top-content .nav-item, #navbar-top-content .dropdown-item {
        flex-basis: 3.5rem;
    }
    #navbar-top-content .dropdown-item {
        min-height: 3.5rem;
    }
    #navbar-top .nav-service-secondary .nav-link > span {
        display: none; /*only icons in 2nd service nav*/
    }
    #navbar-top .show.overflow-fix {
        overflow:hidden;
    }
    .nav-service-secondary {
        position: fixed; /*only way to escape rel. positioned div with overflow-y: auto...*/
        right: 4rem;
        top: 22px;
        border: 0px solid red;
    }
    .nav-service-secondary li,
    .nav-service-secondary li a{
        height: 40px;
        display: inline-block;
    }

    .navbar-brand {
        flex-grow: 1; /*IE*/
    }
    .overflow-hidden {
        overflow: hidden !important;
    }
    body.nav-open {
        overflow: hidden;
        height: 100%;
    }
    body:before {
        position:fixed;
        top:0;
        left:0;
        right:0;
        height: 80px;
        background-color: #52ADE2;
        z-index: 5;
        content:"";
        display: block;
        pointer-events: none;
        opacity: 0;
    }
    body.nav-open:before {
        opacity: 1;
    }
}
@media (min-width: 992px) {
    #navbar-top .mobile {
        display: none;
    }
    #header-top.scrolled.dropdown-open + .nav-bg-helper {
        height: 80px;
    }
    #header-top.dropdown-open +.nav-bg-helper {
        top: 0;
    }
    #header-top:not(.nojs) .nav-main > .dropdown > .dropdown-menu
    {
        overflow: hidden;
    }
    #header-top.nojs .nav-item.dropdown:hover > .dropdown-menu,
    #header-top:not(.nojs) .nav-item.dropdown.show > .dropdown-menu
    {
        display: block;
        padding-top: 19px;
    }
    #header-top:not(.nojs) .nav-item.dropdown > .dropdown-menu > .indicator {
        content: "";
        background-color: #013D8F;
        position: absolute;
        display: inline-block;
        height: 7px;
        top:0;
        left:0;
        transform: translateX(1rem);
        right:0;
    }
    #header-top .nav-main .nav-item.dropdown:hover > .dropdown-menu
    {
        animation: max-height-transition 1s;
    }
    #navbar-top .dropdown-item.prepend-icon {
        position: relative;
        padding-left: 0;
    }
    #navbar-top .dropdown-item.prepend-icon span {
        position: absolute;
        left: -0.25rem;
        height: 1rem;
        top: 13px;
        visibility: hidden;
    }
    #navbar-top .dropdown-item.prepend-icon:hover span {
        visibility: visible;
    }
    #header-top:not(.scrolled) {
        height: 140px;
    }
    #navbar-top {
        padding: 0 1rem;
    }
    #navbar-top .navbar-brand img {
        width: 92px;
    }
    #header-top.scrolled .navbar-brand img {
        width: 54px;
    }
    #navbar-top-content {
        justify-content: space-between;
    }
    #header-top.scrolled .nav-service-secondary .nav-link > span {
        max-width: 0;
        opacity: 0;
    }
    #header-top:not(.scrolled) .nav-service-primary .nav-link,
    #header-top:not(.scrolled) #navbar-top-content li.nav-item .nav-link {
        padding: 16px 12px;
	}
    #header-top:not(.scrolled) #navbar-top-content .nav-main li.nav-item .nav-link {
        padding-bottom: 19px;
	}
	#header-top:not(.scrolled) #navbar-top-content .nav-main .dropdown-menu li .nav-link{
		padding-bottom: .5rem;
	}
	
    #header-top:not(.scrolled) #navbar-top-content ul.dropdown-menu .nav-link {
        padding: .5rem 1rem;
	}
    #navbar-top .navbar-brand {
        flex-basis: 140px;
    }
    .nav-service-primary {
        flex-grow: 1;
        height: 62px
    }
    .nav-service-secondary {
        height: 62px;
    }

    #header-top.scrolled .nav-service-primary,
    #header-top.scrolled .nav-service-secondary {
        height: 42px;
    }

    #navbar-top .nav-service-primary .nav-link {
        color: #6c6e6f;
        font-size: 15px;
    }
    #navbar-top .nav-service-primary .nav-link:hover {
        color: #51aee2;
    }
    #navbar-top .nav-main {
        align-self: flex-start;
    }
    #navbar-top .nav-main > li + li {
        margin-left: 2rem;
    }
    #navbar-top ul.dropdown-menu {
        position: absolute;
        top: 100%;
        border:0;
        box-shadow: 0 6px 5px -5px #CCCCCC; /*needs to be only at the bottom*/
    }
    #navbar-top .nav-main > .nav-item > .dropdown-menu {
        border-radius: 0 0 2rem 0;
    }
    #header-top.nojs .nav-main > .nav-item.dropdown:hover ul.dropdown-menu {
        margin: 0 -100vw;
        padding: .5rem 100vw;
    }
    /*ugly hack for fullscreen dropdown*/
    #header-top.nojs .nav-main > .nav-item > .dropdown-menu:before {
        content:"";
        display:inline-block;
        position:fixed;
        z-index: 9;
        right:0;
        top:100px;
        height: 40px;
        width: 40px;
        background-color:white;
        transition: top .1s ease-in;
    }
    .nav-service-secondary .icon-append .nav-link > span {
        display: inline-block;
        max-width: 20rem;
        /*overflow: hidden; ie11 problem*/
        white-space: nowrap;
        opacity: 1;
        transition: all .15s ease-out;
        margin-right: 0.5rem;
    }
    #header-top {
        transition: height .25s ease-in;
    }
    .navbar-brand img {
        transition: width .25s ease-in;
    }
    #header-top.scrolled {
        transition: height .1s ease-in;
    }
    #header-top.scrolled .navbar-brand img {
        transition: width .1s ease-in;
    }
    #header-top.scrolled.nojs #navbar-top ul.dropdown-menu:before {
        transition: top .1s ease-out;
        top:60px;
    }


    #navbar-top .nav-service-secondary ul.dropdown-menu.langmenu li a {
        color: #ffffff;
    }

    #navbar-top .nav-service-secondary ul.dropdown-menu.langmenu li a:hover {
        color: #003E90;
    }

}
@media (min-width: 1040px){
    #navbar-top .navbar-brand {
        flex-basis: 200px;
    }
}

/* HEADER ICON CSS */
#navbar-top .nav-link svg {
	stroke: #003C8D;
	fill: none;
	transition: all .15s ease-out;
}
#navbar-top .nav-link:hover svg {
    stroke: #51aee2;
    /*fill: #51aee2;*/
}

/* SHOP ICON */
#navbar-top .nav-link svg.icon-shop .st0{fill:none;stroke:#174285;stroke-width:1.3;stroke-linecap:round;}
#navbar-top .nav-link svg.icon-shop .st1{fill:#174285;}

#navbar-top .nav-link:hover svg.icon-shop .st0{fill:none;stroke:#51aee2;stroke-width:1.3;stroke-linecap:round;}
#navbar-top .nav-link:hover svg.icon-shop .st1{fill:#51aee2;}

/* LANGUAGE ICON */
#navbar-top .nav-link svg.icon-language .st0{fill:none;stroke-width:1.3;}

/* SEARCH ICON */
#navbar-top .nav-link svg.icon-search .st0{fill:none;stroke-width:1.3;stroke-linecap:round;}
#navbar-top .nav-link svg.icon-search .st1{fill:none;stroke-width:1.3;}

/* NAV ICON */
#navbar-top .iconfield{
	position: absolute;
	right: 132px;
	top:45px;
}
#navbar-top .iconfield .navicon{
	display: none;
}
#navbar-top .iconfield .navicon.activeIcon{
	display: block;
	width: 150px;
	height: 150px;
}

main .breadcrumb-wrapper {
    background-color: #ffffff;
    margin-bottom: 40px;
}

main .breadcrumb-wrapper .breadcrumb{
    background-color: #ffffff;
    margin-bottom: 40px;
}

ol.breadcrumb {
    padding-left: 0;
}

.breadcrumb-item {
    font-size: 15px;
}

.breadcrumb-item a:hover {
    color: #51aee2;
}

@media only screen and (max-width: 768px) {
    /* MOBILE */

    .breadcrumb-wrapper nav {
        display: none;
    }

}