/*
 * VDS BEWERBUNG
 */
.vds-bewerbung {
	text-align: center;
	font-size: 16px;
	line-height: 23px;
}
.vds-bewerbung h1 {
	color: #2598d5;
	font-size: 19px;
	line-height: 33px;
}
.vds-bewerbung p {
	color: #6c6e6f;
	margin: 0;
}
.vds-bewerbung img {
	width: 121px;
	height: 121px;
	margin-bottom: 30px;
}
.vds-bewerbung .col-12 {
	padding: 0 52px;
}

@media (min-width: 768px) {
	.vds-bewerbung .col-12 {
		margin-bottom: 100px;
	}
}
@media (max-width: 767px) {
	.vds-bewerbung .col-12 {
		margin-bottom: 75px;
	}
}
@media (max-width: 575px) {
	.vds-bewerbung .col-12 {
		margin-bottom: 50px;
	}
}