/*
 * VDS DOWNLOAD BUTTON
 */
.vds-download{
	background-color: #2598d5;
	height: auto;
	min-height: 80px;
	width: 100%;
	display: inline-block;
}

.vds-download .vds-download-text {
	margin-top: 10px;
	margin-left: 17px;
}

.vds-download .vds-download-text {
	font-size: 19px;
	color: #fff;
}

.vds-download .vds-download-img {
	position: absolute;
	top: 15px;
	right: 44px;
	width: 26px;
}

.vds-download .vds-download-type {
	font-size: 12px;
	color: #fff;
	position: absolute;
	right: 46px;
	bottom: 35px;
}

.vds-download:hover {
	text-decoration: none;
}

@media (min-width: 576px) {
	.vds-download .vds-download-type {
		bottom: 5px;
	}
}

@media (max-width: 575px) {
	.vds-download{
		margin-bottom: 30px;
	}
}