/*
 * VDS-Top-Thema
 */

.vds-top-thema {
	padding-right: 60px;
}
@media (max-width: 768px) {
	.vds-top-thema {
		padding-right: 0;
		margin-bottom: 40px;
	}
}


.vds-top-thema h1{
	font-size: 36px;
	line-height: 56px;
	margin-bottom: 20px;
}
@media (max-width: 768px) {
	.vds-top-thema  h1 {
		font-size: 32px;
		line-height: 45px;
	}
}

.vds-top-thema h2 {
	font-size: 16px;
	line-height: 22px;
}

.vds-top-thema,
.vds-top-thema h1,
.vds-top-thema h2 {
	color: #ffffff;
}
.vds-top-thema .bild img{
	width: 100%;
	height: auto;
}
.vds-top-thema .info{
	padding: 35px 20px 20px 20px;
	border-bottom-right-radius: 30px;
}
.vds-top-thema .header{
	width:100%;
	display: inline-block;
}
.vds-top-thema .text{
	display: none;
	margin-bottom: 90px;
	font-size: 16px;
	line-height: 26px;
}

.vds-top-thema .link-arrow{
	height: 20px;
	width:30px;
	display: block;
}

.vds-top-thema .cat-pikto{
	display:none;
	float: right;
	width:72px;
}
.vds-top-thema .cat-pikto img{
	max-width: 100%;
}

@media (min-width: 768px) {
	.vds-top-thema .cat-pikto{
		display:block;
	}
	.vds-top-thema .header{
		width:85%;
	}
	.vds-top-thema .text{
		display: block;
	}
}